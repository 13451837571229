import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getDomains } from '../services/domain';

const useFetchDomains = (userId) => {
  const [userDomains, setUserDomains] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [networkError, setNetworkError] = useState(null);

  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    try {
      if (userId) {
        getDomains(userId, (domains) => {
          setIsLoading(false);
          if (domains?.length) {
            setUserDomains(domains);
          } else if (pathname === '/dashboard') history.push('/setup');
        }, (error) => {
          const { code, message, response } = error;
          if (response?.status === 403) {
            history.push('/login');
          } else {
            const errorMessage = response?.data || `${code} - ${message}`;
            setNetworkError(errorMessage);
          }
        });
      } else setIsLoading(false);
    } catch (e) {
      if (process.env.NODE_ENV === 'development') console.log(e);
    }
  }, [userId]);

  return [userDomains, networkError, isLoading];
};

export default useFetchDomains;
