import React, { useEffect, useState } from 'react';
import Tilt from 'react-parallax-tilt';
import { Link } from 'react-router-dom';

const PricingContent = [
  {
    packName: 'Free Plan',
    features: ['Track single form'],
    activeClass: 'active',
  },
  {
    icon: '86',
    packName: 'Professional Plan',
    features: [
      'Track multiple forms',
      'Optimise forms with analytics',
      'Customer support',
    ],
    price: '5',
    activeClass: 'active',
  },
  {
    icon: '85',
    packName: 'Business Plan',
    features: [
      'Track multiple forms',
      'Optimise forms with analytics',
      'Add tracking to multiple websites',
      'Enjoy customer support',
    ],
    price: '7',
    activeClass: 'most-popular',
  },
];

function Pricing() {
  const [currency, setCurrency] = useState('$');
  const getGeoInfo = () => {
    const locale = Intl?.DateTimeFormat()?.resolvedOptions()?.locale;
    if (locale === 'en-GB') setCurrency('£');
  };

  useEffect(() => {
    getGeoInfo();
  }, []);
  return (
    <div className="row align-items-center">
      {PricingContent.map(({
        activeClass, icon, packName, features, price,
      }) => (
        <div className="col-md-4 mb-30" key={packName}>
          <Tilt>
            <div className={`pr-table-wrapper ${activeClass}`}>
              {
                icon && (
                  <img
                    src={`images/icon/${icon}.svg`}
                    alt="icon"
                    className="icon"
                  />
                )
              }
              <div className="pack-name">{packName}</div>
              <ul className="pr-feature">
                {features.map((list, i) => (
                  <li key={i}>{list}</li>
                ))}
              </ul>
              <div className="price">
                {price ? `${currency}${price}/month` : 'Free'}
              </div>
              <Link
                to="sign-up"
                className="trial-button hover-reverse-gr-bg-one gr-bg-one"
              >
                {' '}
                Get Started
              </Link>
            </div>
            {/* /.pr-table-wrapper */}
          </Tilt>
        </div>
      ))}
    </div>
  );
}

export default Pricing;
