import React from 'react';

const heroContent = {
  title: 'Enhance Your Website Forms with Insiteform',
  description: 'Use Insiteform to easily track your forms, improving retention and increasing sign-ups.',
  buttonText: 'Start Tracking for Free',
};

function HeroBannerEight({ track }) {
  return (
    <div className="hero-banner-eight">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 m-auto">
            <h1 className="hero-heading font-rubik" data-aos="fade-up">
              {heroContent.title}
            </h1>
            <p
              style={{ fontWeight: 'bolder' }}
              className="hero-sub-heading font-rubik"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {heroContent.description}
            </p>
            <a
              href="/sign-up"
              className="theme-btn-nine"
              data-aos="fade-up"
              data-aos-delay="200"
              onClick={() => track({ label: 'Get Started' })}
            >
              {heroContent.buttonText}
            </a>
          </div>
        </div>
      </div>

      {/* /.partner-slider-two */}
      {/* <img
        src="images/shape/173.svg"
        alt="shape"
        className="shapes shape-right"
      />
      <img
        src="images/shape/174.svg"
        alt="shape"
        className="shapes shape-left"
      /> */}
    </div>
    //   /.hero-banner-eight
  );
}

export default HeroBannerEight;
