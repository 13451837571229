import React from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import theme from '../../../../theme';

export default function SessionAreachart({
  data, axisLabel, fill, dataName,
}) {
  return (
    <ResponsiveContainer width="99%">
      <AreaChart
        data={data}
        margin={{
          top: 30,
          bottom: 20,
        }}
      >
        <CartesianGrid key="grid" />
        <XAxis dataKey="field" style={{ fontSize: 15 }} />
        <YAxis
          allowDecimals={false}
          style={{ fontSize: 15 }}
          type="number"
          label={{
            value: axisLabel.toUpperCase(),
            position: 'top',
            offset: 8,
            fontSize: 11,
            dy: -10,
            dx: 20,
            fill: theme.colors.textLight,
          }}
        />
        <Tooltip />
        <Area type="monotone" dataKey="value" stroke={fill} fill={fill} name={dataName} />
      </AreaChart>
    </ResponsiveContainer>
  );
}
