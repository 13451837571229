import axios from 'axios';
import { useHistory } from 'react-router-dom';
import authHeader from './utils';

const API_URL_CREATE = `${process.env.REACT_APP_API_BASE_URL}/user-domain/create-form-domain`;
const API_URL_GET = `${process.env.REACT_APP_API_BASE_URL}/user-domain/get-domains`;
const API_URL_PATCH = `${process.env.REACT_APP_API_BASE_URL}/user-domain/patch-domain`;

const createDomain = ({ userId, domainName, trackedPages }, next, error) => {
  if (userId) {
    axios.post(
      API_URL_CREATE,
      { userId, domainName, trackedPages },
      { headers: authHeader() },
    )
      .then(() => next())
      .catch((e) => error(e));
  } else {
    const history = useHistory();
    history.push('/sign-up');
  }
};

const getDomains = (userId, next, error) => axios.get(
  `${API_URL_GET}/${userId}`,
  { headers: authHeader() },
).then(({ data }) => {
  next(data);
}).catch((e) => error(e));

const editDomainPages = ({ userId, domainKey, trackedPages }, next, error) => axios.patch(
  `${API_URL_PATCH}/${userId}/${domainKey}`,
  {
    trackedPages,
  },
  { headers: authHeader() },
).then(() => {
  next();
}).catch((e) => error(e));

const deleteDomain = ({ userId, domainKey }, next, error) => axios.patch(
  `${API_URL_PATCH}/${userId}/${domainKey}`,
  {
    isDeleted: true,
  },
  { headers: authHeader() },
).then(() => {
  next();
}).catch((e) => error(e));

export {
  getDomains, createDomain, editDomainPages, deleteDomain,
};
