/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import authHeader from './utils';

const API_URL_GET = `${process.env.REACT_APP_API_BASE_URL}/domain-session/get-session`;

const getSessions = ({ domainId, startDate, endDate }, next, error) => axios.get(
  `${API_URL_GET}/${domainId}?start=${startDate}&end=${endDate}`,
  { headers: authHeader() },
).then(({ data }) => {
  next(data);
}).catch((e) => error(e));

export {
  getSessions,
};
