/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { sendMessage } from '../../services/messaging';

const notifySuccess = (closeModal) => {
  toast.success('Sent! We will be in touch soon.', {
    position: 'top-right',
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
  });
  closeModal();
};

const notifyError = (message) => toast.error(message || 'Error', {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

function ContactForm({
  closeModal, userName, userEmail, userId,
}) {
  const validationSchema = Yup.object().shape({
    message: Yup.string().required('Please leave us a message'),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data, e) {
    const userMessage = {
      userName, userEmail, userId, ...data,
    };
    sendMessage(userMessage, () => notifySuccess(closeModal), (error) => {
      const { code, message, response } = error;
      const errorMessage = response.data || `${code} - ${message}`;
      notifyError(errorMessage);
    });
    e.target.reset();
  }

  return (
    <form id="contact-form" onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <div className="messages" />
      <div className="row controls">
        <div className="col-12">
          <div className="input-group-meta form-group lg mb-40">
            <label>Message</label>
            <textarea
              placeholder="Want to suggest a feature? Need some additional help? Let us know!"
              name="message"
              type="text"
              {...register('message')}
              className={`${errors.message ? 'is-invalid' : ''}`}
            />
            {errors.message && (
              <div className="invalid-feedback">
                {errors.message?.message}
              </div>
            )}
          </div>
        </div>
        {/* End .col */}

        <div className="col-12">
          <button type="submit" className="theme-btn-two" style={{ float: 'right' }}>
            Send
          </button>
        </div>
        {/* End .col */}
      </div>
    </form>
  );
}

export default ContactForm;
