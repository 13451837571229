import React from 'react';
import moment from 'moment';
import theme from '../../theme';

const analyticsBannerStyle = {
  padding: '0 32px',
  fontSize: 15,
  background: theme.colors.goldUpgrade,
  gap: 8,
  color: '#fff',
};

const FREE_SESSION_LIMIT = 1000;

function DashboardBanner({
  activeSidebarGroup, isFreePackage, activeDomain, billingEnd,
}) {
  if (billingEnd) {
    return (
      <div
        className="font-rubik"
        style={analyticsBannerStyle}
      >
        Package features active until
        {' '}
        {moment.unix(billingEnd).format('Do MMM YY')}
      </div>
    );
  }
  if (isFreePackage && activeSidebarGroup === 'sessions' && activeDomain) {
    const sessionsUsed = (Math.max(activeDomain.freeSessionCount, 0) / FREE_SESSION_LIMIT) * 100;
    const warning = sessionsUsed >= 90 ? {
      background: theme.colors.redLight,
      color: theme.colors.grayedOut,
    } : {};
    return (
      <div className="font-rubik" style={{ padding: '0 32px', fontSize: 13, ...warning }}>
        {Math.max(FREE_SESSION_LIMIT - activeDomain.freeSessionCount, 0) }
        {' '}
        free visitor sessions remaining
      </div>
    );
  }
  if (isFreePackage && activeSidebarGroup === 'analytics') {
    return (
      <div className="font-rubik d-flex align-items-center justify-content-center" style={analyticsBannerStyle}>
        <i className="fa fa-diamond" aria-hidden="true" />
        Upgrade package to access insights
      </div>
    );
  }
  return null;
}

export default DashboardBanner;
