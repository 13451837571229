/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Helmet } from 'react-helmet';
import ScrollspyNav from 'react-scrollspy-nav';
import CopyRight from '../components/footer/CopyRight';

function TermsConditions() {
  return (
    <div className="doc-container main-page-wrapper pt-0">
      <Helmet>
        <title>
          Terms & Conditions - Insiteform
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={['opt1', 'opt2']}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#opt1">
                      1. Terms and conditions
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt2">
                      2. Privacy policy
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content">
                  <div id="opt1">
                    <h2 className="font-rubik">Terms & Conditions</h2>
                    <div className="update-date">LAST UPDATED: 1/1/2023</div>
                    <p>
                      By using Insiteform, you agree to be bound by these terms and conditions.
                      If you do not agree to these terms and conditions,
                      you should not use Insiteform.
                      We reserve the right to modify these terms and conditions at any time.
                      If we make material changes to these terms and conditions,
                      we will post the updated terms and conditions on our website
                      and update the effective date. It is your responsibility to
                      review these terms and conditions periodically for any changes.
                      Your continued use of Insiteform after any changes to these
                      terms and conditions
                      will constitute your acceptance of such changes.
                      Insiteform is a product and service provided by us,
                      and you may use it only in accordance with
                      these terms and conditions and all applicable laws.
                      You may not use Insiteform for any illegal or unauthorised purpose.
                      You are responsible for your own use of Insiteform,
                      and for any use of Insiteform by any person using your account.
                      We grant you a limited, non-exclusive, non-transferable,
                      and revocable license to use Insiteform for your personal and commercial
                      purposes.
                      You may not modify, reverse engineer, or otherwise attempt to
                      access the source code of Insiteform.
                      We reserve the right to modify, suspend, or discontinue Insiteform at
                      any time without notice. We will not be liable to you or any
                      third party for any modification, suspension,
                      or discontinuation of Insiteform.
                      Insiteform is provided &quot;as is&quot; without warranty of any kind.
                      We disclaim all warranties, express or implied, including,
                      but not limited to, the implied warranties of merchantability,
                      fitness for a particular purpose, and non-infringement.
                      We do not guarantee that Insiteform will be available,
                      error-free, or free from viruses or other harmful components.
                      You are solely responsible for any damage to your device or
                      loss of data that may result from your use of Insiteform.
                      We will not be liable for any indirect, incidental,
                      consequential, or punitive damages arising from your use of Insiteform.
                      We respect the intellectual property rights of others
                      and expect you to do the same.
                      We will not be liable for any infringement of intellectual property rights
                      that may arise from your use of Insiteform.
                    </p>
                    <p>
                      If you have any questions or concerns about these terms and conditions,
                      please contact us at
                      {' '}
                      <a href="#">team@insiteform.com</a>
                      .
                    </p>
                    <p>These terms and conditions are effective as of 1/1/2023</p>
                  </div>
                  <div id="opt2">
                    <h2 className="font-rubik">Privacy policy</h2>
                    <div className="update-date">LAST UPDATED: 1/1/2023</div>
                    <p>
                      We at Insiteform are committed to protecting the
                      privacy of our users and customers.
                      We understand that your personal information is important to you
                      and we want you to feel secure when using our product.
                      This privacy policy explains how we collect, use, and disclose
                      your personal information when you use Insiteform.
                      When you use Insiteform, we may collect certain personal
                      information from you, such as your name, email address,
                      and IP address. We may also collect information about your
                      device, such as its type and operating system. We may use this
                      information to provide you with the best possible experience
                      when using Insiteform, and to improve our product and services.
                      We may also use your personal information to contact you about
                      our products and services, or to send you promotional materials.
                      If you do not want to receive such communications, you can opt out
                      by following the instructions in the communication or by
                      contacting us directly.
                      Insiteform retains user data for as long as it is deemed necessary
                      for the purposes for which it was collected.
                      This may include retaining data for as long as necessary to provide
                      the services requested by users, to comply with legal obligations,
                      or to resolve disputes.
                      We may disclose your personal information to third parties
                      in certain circumstances, such as when required by law or to
                      protect our rights and property. We may also share your personal
                      information with third-party service providers who
                      perform services on our behalf, such as hosting and maintenance services.
                      We take appropriate measures to protect your personal
                      information from unauthorized access, disclosure,
                      alteration, or destruction. However, no security measures are
                      perfect and we cannot guarantee the security of your personal information.
                      We may update this privacy policy from time to time.
                      When we do, we will post the updated policy on our website and
                      update the effective date. We encourage you to review this privacy
                      policy periodically to stay informed about how we are
                      protecting your personal information.
                    </p>
                    <p>
                      If you have any questions or concerns about our privacy policy
                      or the use of your personal information,
                      please contact us at
                      {' '}
                      <a href="#">team@insiteform.com</a>
                      .
                    </p>
                    <p>This privacy policy is effective as of 1/1/2023</p>
                  </div>
                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
}

export default TermsConditions;
