/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import theme from '../../theme';

const pricingContent = (isYearly) => [
  {
    skewClass: 'skew-right',
    packageClass: 'pc1',
    title: 'Personal',
    packageName: 'free',
    price: null,
    priceMeta: 'Billed monthly',
    featureList: [
      {
        list: 'Track single form',
        listClass: '',
      },
      {
        list: 'Unlimited tracking capabilities',
        listClass: 'disable',
      },
      {
        list: 'Form analytics for performance insights',
        listClass: 'disable',
      },
      {
        list: 'Activate tracking on multiple websites',
        listClass: 'disable',
      },
      {
        list: 'Customer support available',
        listClass: 'disable',
      },
    ],
    animatinDelay: '0',
  },
  {
    skewClass: 'skew-left',
    packageClass: 'pc2',
    title: 'Professional',
    packageName: 'tier-1',
    price: isYearly ? 5 : 7,
    priceMeta: isYearly ? 'Billed yearly' : 'Billed monthly',
    featureList: [
      {
        list: 'Track multiple forms with ease',
        listClass: '',
      },
      {
        list: 'Unlimited tracking capabilities',
        listClass: '',
      },
      {
        list: 'Form analytics for performance insights',
        listClass: '',
      },
      {
        list: 'Activate tracking on multiple websites',
        listClass: 'disable',
      },
      {
        list: 'Customer support available',
        listClass: '',
      },
    ],
    animatinDelay: '100',
  },
  {
    skewClass: 'skew-right',
    packageClass: 'pc3',
    title: 'Business',
    packageName: 'tier-2',
    price: isYearly ? 7 : 10,
    priceMeta: isYearly ? 'Billed yearly' : 'Billed monthly',
    featureList: [
      {
        list: 'Track multiple forms with ease',
        listClass: '',
      },
      {
        list: 'Unlimited tracking capabilities',
        listClass: '',
      },
      {
        list: 'Form analytics for performance insights',
        listClass: '',
      },
      {
        list: 'Activate tracking on multiple websites',
        listClass: '',
      },
      {
        list: 'Customer support available',
        listClass: '',
      },
    ],
    animatinDelay: '200',
  },
];

function PackageButton({
  isActivePackage, currentPackage, packageName, onClick,
}) {
  if (isActivePackage) {
    return (
      <button
        onClick={null}
        type="button"
        className="subscribe-btn font-roboto"
        style={{ background: theme.colors.main, color: '#FFFF' }}
      >
        Current Package
      </button>
    );
  }
  if (packageName !== 'free' && currentPackage !== 'tier-2') {
    return (
      <button
        onClick={onClick}
        type="button"
        className="subscribe-btn font-roboto"
      >
        Upgrade Now
      </button>
    );
  }
  return null;
}

function PackageList({
  currentPackage, isYearly, currency, onSelectPackage,
}) {
  return (
    <div className="row justify-content-center">
      {pricingContent(isYearly).map(({
        animatinDelay, skewClass, packageClass,
        title, packageName,
        priceMeta, price,
        featureList,
      }, i) => {
        const isActivePackage = packageName === currentPackage;
        return (
          <div
            className="col-lg-4 col-md-6"
            key={i}
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay={animatinDelay}
          >
            <div className={`pr-table-wrapper ${skewClass}`} style={{ userSelect: 'none' }}>
              <div className={`pack-name font-rubik ${packageClass}`}>
                <span>{title}</span>
              </div>
              <div className="price font-rubik">
                {price ? `${currency.sign}${price}/month` : 'Free'}
              </div>
              <p className="user-condition font-rubik" style={price ? {} : { color: '#fff' }}>{priceMeta}</p>
              <img src="/images/shape/114.svg" alt="shape" className="line" />
              <ul>
                {featureList.map((val, idx) => (
                  <li className={val.listClass} key={idx}>
                    {val.list}
                  </li>
                ))}
              </ul>
              <PackageButton
                isActivePackage={isActivePackage}
                currentPackage={currentPackage}
                packageName={packageName}
                onClick={() => onSelectPackage({
                  packageName,
                  label: `${isYearly ? 'Yearly' : 'Monthly'} ${title}`,
                  displayPrice: price,
                  currency,
                })}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PackageList;
