/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import * as R from 'ramda';
import { useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CircularProgress } from '@mui/material';
import moment from 'moment';

import DateFilter from '../../components/dashboard/DateFilter';
import SessionFilter from '../../components/dashboard/SessionFilter';
import HeaderSignpost from '../../components/dashboard/HeaderSignpost';
import DashboardBanner from '../../components/dashboard/Banner';
import DashboardSidebar from '../../components/dashboard/DashboardSidebar';
import DashboardModal from '../../components/dashboard/DashboardModal';
import DomainEditor from '../../components/dashboard/DomainEditor';
import AccountEditor from '../../components/dashboard/AccountEditor';
import Contact from '../../components/dashboard/Contact';
import DomainFormsSessions from '../../components/dashboard/sessions/DomainFormsSessions';
import DomainFormsAnalytics from '../../components/dashboard/analytics/DomainFormsAnalytics';
import { getCurrentUser } from '../../services/auth';
import { useFetchDomains, useFetchSessions } from '../../hooks';

const headerStyle = {
  background: '#FFFFFF',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
};
const headerLeftStyle = {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  gap: 16,
  padding: 16,
  flexWrap: 'wrap',
};

const headerRightStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: 24,
  padding: 16,
};

const viewComponents = {
  sessions: DomainFormsSessions,
  analytics: DomainFormsAnalytics,
};

function Dashboard() {
  const {
    authenticatedUser: {
      userId, packageName, name, email, billingEnd,
    },
  } = getCurrentUser();
  const [userDomains, domainError, domainLoading] = useFetchDomains(userId);

  const isFreePackage = packageName === 'free';

  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const selectedDomainInQuery = query.get('selectedDomain');
  const domainQueryIndex = R.findIndex(R.propEq('domainName', selectedDomainInQuery))(userDomains);
  const selectedDomainIndex = domainQueryIndex >= 0 ? domainQueryIndex : 0;

  const activeDomain = userDomains[selectedDomainIndex];
  const activeSidebarGroup = query.get('activeSection') || 'sessions';
  const activeSidebarSection = `${activeSidebarGroup}${activeDomain?.domainName}`;

  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(7, 'days').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });

  const [filterStatus, setFilterStatus] = useState('all');

  const [domainSessions, sessionError, sessionLoading] = useFetchSessions(
    activeDomain?._id,
    dateRange.startDate,
    dateRange.endDate,
  );

  const [modalShouldOpen, setModalShouldOpen] = useState(null);
  const modalComponents = {
    domain: (<DomainEditor
      selectedDomain={activeDomain}
      closeModal={setModalShouldOpen}
      packageName={packageName}
      userId={userId}
    />),
    account: (<AccountEditor
      userName={name}
      userEmail={email}
      userId={userId}
      packageName={packageName}
      billingEnd={billingEnd}
      closeModal={setModalShouldOpen}
    />),
    contact: (<Contact
      closeModal={setModalShouldOpen}
      userName={name}
      userEmail={email}
      userId={userId}
    />),
  };

  const handleChangeRange = (range) => {
    setDateRange(range);
  };

  const handleChangeFilter = (event) => {
    setFilterStatus(event.target.value);
  };

  const onChangeSection = (domain, tab) => {
    history.push(`?selectedDomain=${domain}&activeSection=${tab}`);
  };

  const DashboardContent = viewComponents[activeSidebarGroup];

  if (domainLoading || sessionLoading) {
    return (
      <div className="dashboard-content" style={{ minHeight: '100vh' }}>
        <div style={{ position: 'absolute', top: '40%', right: '50%' }}>
          <CircularProgress color="inherit" />
        </div>
      </div>
    );
  }

  if (domainError || sessionError) {
    return (
      <div className="main-page-wrapper pb-100 pt-0" style={{ minHeight: '100vh' }}>
        {sessionError || domainError}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Dashboard - Insiteform
        </title>
      </Helmet>

      <div className="main-page-wrapper pt-0">
        <div className="dashboard-container d-flex flex-wrap justify-content-start align-items-start flex-row w-100 h-100">
          <DashboardSidebar
            setModalOpen={setModalShouldOpen}
            disableUserMenu={false}
            userDomains={userDomains}
            activeSidebarSection={activeSidebarSection}
            onChangeSection={onChangeSection}
            packageName={packageName}
            userName={name}
          />

          <main style={{ height: '100vh', width: '100%' }}>
            <div style={headerStyle}>
              <div style={headerLeftStyle}>
                <HeaderSignpost
                  activeDomain={activeDomain}
                  activeSidebarGroup={activeSidebarGroup}
                  setEditModalOpen={setModalShouldOpen}
                  editDisabled={false}
                />
              </div>
              <div style={headerRightStyle}>
                {activeSidebarGroup === 'sessions' && (
                  <SessionFilter
                    filterStatus={filterStatus}
                    handleChangeFilter={handleChangeFilter}
                  />
                )}
                <DateFilter
                  range={dateRange}
                  handleChange={handleChangeRange}
                />
              </div>
            </div>
            <DashboardBanner
              activeDomain={activeDomain}
              activeSidebarGroup={activeSidebarGroup}
              isFreePackage={isFreePackage}
              billingEnd={billingEnd}
            />

            <div className="dashboard-content">
              <DashboardContent
                forms={activeDomain?.trackedPages}
                dateRange={dateRange}
                formSessions={domainSessions}
                filterStatus={filterStatus}
                isFreePackage={isFreePackage}
              />
            </div>
          </main>
        </div>
        <DashboardModal
          modalIsOpen={modalShouldOpen}
          closeModal={setModalShouldOpen}
        >
          {modalShouldOpen && modalComponents[modalShouldOpen]}
        </DashboardModal>
      </div>
    </>
  );
}

export default Dashboard;
