import React from 'react';
import theme from '../../../theme';

const wrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
};
const chartContainerStyle = {
  display: 'flex',
  flex: 1,
};
const answerWrapper = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '16px 0 0',
  gap: 8,
};
const answerContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
};
const answerSectionWrapper = {
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
};
const leadAnswerTextStyle = {
  fontSize: 15,
  lineHeight: '18px',
};
const valueAnswerTextStyle = {
  color: theme.colors.main,
  fontSize: 15,
  lineHeight: '18px',
};

export default function ChartWrapper({ children, chartHeight, answer }) {
  return (
    <div style={{ ...wrapperStyle, minHeight: chartHeight, height: chartHeight }}>
      <span style={{ fontSize: 13, textAlign: 'right' }}>
        Details on hover
      </span>
      <div style={chartContainerStyle}>
        {children}
      </div>
      {
        answer.length !== 0 && (
          <div style={answerWrapper}>
            <i className="fa fa-calculator" aria-hidden="true" style={{ color: theme.colors.main }} />
            <div style={answerContainerStyle}>
              {answer.map(({ lead, value }) => (
                <div key={value} style={answerSectionWrapper}>
                  <div style={leadAnswerTextStyle}>
                    {lead}
                  </div>
                  <div style={valueAnswerTextStyle}>
                    {value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      }
    </div>
  );
}
