import React, { useState } from 'react';
import {
  Menu, MenuItem, Tooltip,
} from '@mui/material';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import theme from '../../theme';
import { logout } from '../../services/auth';

const siderbarMenuWrapperStyle = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: 24,
  justifyContent: 'flex-end',
  paddingBottom: 40,
  fontSize: 16,
};

const addButtonStyle = {
  width: '100%',
  gap: 8,
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '0 16px',
  alignItems: 'center',
  color: theme.colors.sidebarBackground,
  border: 'solid 1px #ffff',
  backgroundColor: '#ffff',
  borderRadius: 8,
  marginBottom: 24,
};

const imageContainerStyle = {
  position: 'relative',
  height: 100,
  width: '100%',
  marginBottom: 16,
  borderBottom: `solid 1px ${theme.colors.main}`,
  color: theme.colors.main,
};

const navbarStyle = {
  flexDirection: 'column',
  height: '100%',
  zIndex: 2000,
  maxWidth: 250,
  overflowX: 'hidden',
};

const menuItemStyle = {
  minWidth: 300,
  justifyContent: 'center',
};

const userProfileRowStyle = {
  marginTop: 24,
  gap: 16,
  justifyContent: 'flex-start',
  flex: 1,
  alignItems: 'flex-end',
};

const userIconStyle = {
  background: theme.colors.main,
  padding: '4px 0',
  borderRadius: 32,
  minWidth: 40,
};

function AddDomainButton({ onClick, isDisabled }) {
  return (
    <button
      disabled={isDisabled}
      type="button"
      {...(isDisabled && { className: 'contact-button disabled' })}
      style={addButtonStyle}
      onClick={onClick}
    >
      <i className="fa fa-plus-circle" aria-hidden="true" />
      Add form
    </button>
  );
}

function ContactButton({ canContact, onClick }) {
  const contactButton = (
    <button
      type="button"
      className={canContact ? 'contact-button' : 'contact-button disabled'}
      onClick={canContact ? onClick : null}
    >
      <i className="fa fa-comments" aria-hidden="true" />
      Need any help?
    </button>
  );

  if (canContact) return contactButton;
  return (
    <Tooltip title="Upgrade to get personalised help" placement="right">
      {contactButton}
    </Tooltip>

  );
}

function UpgradeButton({ onClick }) {
  return (
    <button
      type="button"
      className="upgrade-button"
      onClick={onClick}
    >
      <i className="fa fa-diamond" aria-hidden="true" />
      Upgrade package
    </button>
  );
}

function UserMenu({
  userName, setModalOpen, openPricingPage, isDisabled,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <button
        type="button"
        style={{ width: '100%', color: '#ffff' }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <div
          className="d-flex flex-row"
          style={userProfileRowStyle}
        >
          <div style={userIconStyle}>{Array.from(userName)[0]}</div>
          <div>{userName}</div>
        </div>
      </button>
      <Menu
        className="menu-popover"
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          disabled={isDisabled}
          style={{ ...menuItemStyle, color: theme.colors.main }}
          onClick={openPricingPage}
        >
          Packages
        </MenuItem>
        <MenuItem
          disabled={isDisabled}
          style={{ ...menuItemStyle, color: theme.colors.main }}
          onClick={() => {
            setModalOpen();
            handleClose();
          }}
        >
          My account
        </MenuItem>
        <MenuItem
          disabled={isDisabled}
          style={{ ...menuItemStyle, color: theme.colors.red }}
          onClick={logout}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

const sidebarContent = (domains, activeSidebarSection) => [
  {
    title: 'Sessions',
    content: R.map(({ domainName }) => ({
      itemName: domainName,
      activeClass: activeSidebarSection === `sessions${domainName}` ? 'active' : '',
    }), domains),
    expand: 'sessions',
  },
  {
    title: 'Analytics',
    content: R.map(({ domainName }) => ({
      itemName: domainName,
      activeClass: activeSidebarSection === `analytics${domainName}` ? 'active' : '',
    }), domains),
    expand: 'analytics',
  },
];

function DashboardSidebar({
  setModalOpen,
  userDomains,
  activeSidebarSection,
  onChangeSection,
  packageName,
  userName,
  disableUserMenu,
}) {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const toggleSidebar = () => setSidebarIsOpen(!sidebarIsOpen);
  const history = useHistory();
  const isFree = packageName === 'free';
  const isBussiness = packageName === 'tier-2';

  return (
    <div className="col-md-2 col-xl-2 doc-sidebar" style={{ minWidth: 'fit-content' }}>
      <div className="mob-header">
        <button type="button" className="toggler-menu" onClick={toggleSidebar}>
          <div className={sidebarIsOpen ? 'active' : ''}>
            <span />
            <span />
            <span />
          </div>
        </button>
      </div>
      {/* End Header */}
      <nav
        className={sidebarIsOpen
          ? 'doc-links collapse clearfix nav-fix  show'
          : 'doc-links collapse clearfix nav-fix'}
        id="doc-sidebar-nav"
        style={{ ...navbarStyle, display: sidebarIsOpen ? 'flex' : '' }}
      >
        <div className="accordion-style-two doc-sidebar-menu">
          <div style={imageContainerStyle}>
            <img
              src="/images/logo/insiteform-full.svg"
              alt="brand logo"
              style={{
                height: '120%', left: '-90px',
              }}
            />
          </div>
          <Accordion preExpanded={['sessions', 'analytics']} allowZeroExpanded allowMultipleExpanded>
            {sidebarContent(userDomains, activeSidebarSection).map(({
              expand,
              title,
              content,
            }, idx) => (
              <AccordionItem className="card" key={idx} uuid={expand}>
                <AccordionItemHeading className="card-header">
                  <AccordionItemButton>
                    <button type="button" className="btn btn-link">{title}</button>
                    {' '}
                  </AccordionItemButton>
                </AccordionItemHeading>
                {/* Accordion Heading */}
                <AccordionItemPanel className="card-body fadeInUp">
                  <ul className="sub-menu">
                    {content.map(({ activeClass, itemName }, i) => (
                      <li key={i}>
                        <button type="button" className={activeClass} onClick={() => onChangeSection(itemName, expand)}>
                          {itemName}
                        </button>
                      </li>
                    ))}
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div style={siderbarMenuWrapperStyle}>
          <AddDomainButton onClick={() => history.push('/setup')} isDisabled={disableUserMenu} />
          {!isBussiness && !disableUserMenu && <UpgradeButton onClick={() => history.push('/packages')} isDisabled={disableUserMenu} />}
          <ContactButton canContact={!isFree} onClick={() => setModalOpen('contact')} />
          <UserMenu
            isDisabled={disableUserMenu}
            userName={userName}
            setModalOpen={() => setModalOpen('account')}
            openPricingPage={() => history.push('/packages')}
          />
        </div>
      </nav>
    </div>

  );
}

export default DashboardSidebar;
