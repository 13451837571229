/* eslint-disable consistent-return */
import * as R from 'ramda';
import { capitalize } from '@mui/material';
import moment from 'moment';
import {
  isCompleted, getAverage, generateDaysOfWeek,
} from './utils';

const areachartData = (sessions) => {
  try {
    const fields = R.pipe(
      R.pluck('formData'),
      R.flatten,
      R.filter(({ fieldType }) => fieldType !== 'button' && fieldType !== 'submit'),
    )(sessions);

    const allFieldIds = R.pipe(R.pluck('id'), R.flatten, R.uniq)(fields);

    const filledFields = R.filter(({ visitorInteraction }) => visitorInteraction)(fields);
    const filledFieldCounts = R.countBy(R.prop('id'))(filledFields);

    const getFieldName = (fieldId) => fields.find(({ id }) => id === fieldId)?.fieldName;

    const fieldsByIdWithCount = R.map((fieldId) => ({
      field: getFieldName(fieldId) ?? 'N/A', value: filledFieldCounts[fieldId] ?? 0,
    }))(allFieldIds);

    const fieldsByCount = R.sortWith(
      [
        R.descend(R.prop('value')),
        R.ascend(R.compose(R.toLower, R.toString, R.prop('field'))),
      ],
      fieldsByIdWithCount,
    );

    const mostFilled = R.prop(0, fieldsByCount);
    const leastFilled = R.prop(-1, fieldsByCount);

    const processedAnswer = [
      {
        lead: 'Most filled field',
        value: `${mostFilled.field} [${mostFilled.value}]`,
      },
      {
        lead: 'Least filled field',
        value: `${leastFilled.field} [${leastFilled.value}]`,
      },
    ];
    return { processedData: fieldsByIdWithCount, processedAnswer };
  } catch (e) {
    if (process.env.NODE_ENV === 'development') console.log(e);
  }
};

const piechartCompletionData = (sessions) => {
  try {
    const formSessions = R.pluck('formData')(sessions);

    const sessionStatuses = R.reduce((a, form) => {
      const counts = a;
      if (isCompleted(form)) counts.completed += 1;
      else counts.abandoned += 1;
      return counts;
    }, { completed: 0, abandoned: 0 })(formSessions);

    const sessionCompletion = R.map(([name, value]) => ({
      name: capitalize(name), value,
    }))(Object.entries(sessionStatuses));

    const completionStatus = sessionCompletion.sort((a, b) => b.value - a.value)[0];

    const processedAnswer = [
      {
        lead: 'Most likely session outcome',
        value: `${completionStatus.name} [${completionStatus.value}]`,
      },
    ];
    return { processedData: sessionCompletion, processedAnswer };
  } catch (e) {
    if (process.env.NODE_ENV === 'development') console.log(e);
  }
};

const barchartNumberOfSessionsData = (sessions, dateRange) => {
  try {
    const sessionDates = generateDaysOfWeek(dateRange);
    const sessionsByDate = R.pipe(
      R.map((i) => ({ date: moment(Date.parse(i.entryTime)).format('Do MMM YY') })),
      R.countBy(R.prop('date')),
    )(sessions);

    const numOfSessions = R.map((date) => ({
      date,
      value: sessionsByDate[date] ?? 0,
    }))(sessionDates);
    const processedAnswer = [
      {
        lead: 'Total number of form starts',
        value: numOfSessions.reduce(
          (a, c) => a + c.value,
          0,
        ),
      },
    ];
    return { processedData: numOfSessions, processedAnswer };
  } catch (e) {
    if (process.env.NODE_ENV === 'development') console.log(e);
  }
};

const barchartTotalTimeSpentData = (sessions, dateRange) => {
  try {
    const sessionDates = generateDaysOfWeek(dateRange);
    const sessionsByDate = R.pipe(
      R.map((i) => ({ date: moment(Date.parse(i.entryTime)).format('Do MMM YY'), ...i })),
      R.groupBy(R.prop('date')),
    )(sessions);

    const sessionTimeByDay = R.map((date) => {
      const sessionsInDay = sessionsByDate[date]
        ? sessionsByDate[date].map((d) => Math.ceil(moment(Date.parse(d.exitTime)).diff(moment(Date.parse(d.entryTime)), 'minutes', true))) : [0];
      return {
        date,
        value: sessionsInDay.reduce(
          (a, c) => c + a,
        ),
      };
    })(sessionDates);

    const processedAnswer = [
      {
        lead: 'Total session time',
        value: `${sessionTimeByDay.reduce(
          (a, c) => a + c.value,
          0,
        )} minutes`,
      },
    ];
    return { processedData: sessionTimeByDay, processedAnswer };
  } catch (e) {
    if (process.env.NODE_ENV === 'development') console.log(e);
  }
};

const barchartAverageTimeSpentData = (sessions, dateRange) => {
  try {
    const sessionDates = generateDaysOfWeek(dateRange);
    const sessionsByDate = R.pipe(
      R.map((i) => ({ date: moment(Date.parse(i.entryTime)).format('Do MMM YY'), ...i })),
      R.groupBy(R.prop('date')),
    )(sessions);

    const sessionTimeByDay = R.map((date) => {
      const sessionsInDay = sessionsByDate[date]
        ? sessionsByDate[date].map((d) => moment(Date.parse(d.exitTime)).diff(moment(Date.parse(d.entryTime)), 'seconds')) : [0];
      return {
        date,
        value: getAverage(sessionsInDay.reduce(
          (a, c) => c + a,
        ), sessionsInDay.length),
      };
    })(sessionDates);

    const processedAnswer = [
      {
        lead: 'Average session time',
        value: `${getAverage(sessionTimeByDay.reduce(
          (a, c) => a + c.value,
          0,
        ), sessionTimeByDay.length)} seconds`,
      },
    ];
    return { processedData: sessionTimeByDay, processedAnswer };
  } catch (e) {
    if (process.env.NODE_ENV === 'development') console.log(e);
  }
};

const piechartDeviceData = (sessions) => {
  try {
    const deviceCounts = R.reduce((a, { isMobile }) => {
      const counts = a;
      if (isMobile) counts.mobile += 1;
      else counts.web += 1;
      return counts;
    }, { web: 0, mobile: 0 })(sessions);

    const sessionDevices = R.map(([name, value]) => ({
      name: capitalize(name), value,
    }))(Object.entries(deviceCounts));

    const topDevice = sessionDevices.sort((a, b) => b.value - a.value)[0];

    const processedAnswer = [
      {
        lead: 'Most popular device type',
        value: `${topDevice.name} [${topDevice.value}]`,
      },
    ];
    return { processedData: sessionDevices, processedAnswer };
  } catch (e) {
    if (process.env.NODE_ENV === 'development') console.log(e);
  }
};

const histogramDeviceCompletionData = (sessions) => {
  try {
    const completionByDeviceCounts = R.reduce((a, session) => {
      const counts = a;
      const formSessions = R.prop('formData')(session);
      if (session.isMobile) {
        if (isCompleted(formSessions)) counts.mobile.completed += 1;
        else counts.mobile.abandoned += 1;
      } else if (isCompleted(formSessions)) counts.web.completed += 1;
      else counts.web.abandoned += 1;
      return counts;
    }, { web: { completed: 0, abandoned: 0 }, mobile: { completed: 0, abandoned: 0 } })(sessions);

    const sessionDevices = R.map(([name, value]) => ({
      name: capitalize(name), ...value,
    }))(Object.entries(completionByDeviceCounts));

    return { processedData: sessionDevices, processedAnswer: [] };
  } catch (e) {
    if (process.env.NODE_ENV === 'development') console.log(e);
  }
};

const barchartLoactionData = (sessions) => {
  try {
    const locationCount = R.pipe(
      R.pluck('visitorRegion'),
      R.countBy((r) => r),
    )(sessions);

    const sessionLocations = R.map(([name, value]) => ({
      name: capitalize(name), value,
    }))(Object.entries(locationCount));

    const topLocation = sessionLocations.sort((a, b) => b.value - a.value)[0];

    const processedAnswer = [
      {
        lead: 'Most popular location',
        value: `${topLocation.name} [${topLocation.value}]`,
      },
    ];
    return { processedData: sessionLocations, processedAnswer };
  } catch (e) {
    if (process.env.NODE_ENV === 'development') console.log(e);
  }
};

const histogramVisitorLocationData = (sessions) => {
  try {
    const sessionsByVisitorLocation = R.groupBy(R.prop('visitorRegion'))(sessions);

    const sessionCompletionByLocation = R.map(([location, locationSessions]) => {
      const formSessions = R.pluck('formData')(locationSessions);
      const completionCounts = R.reduce((a, session) => {
        const counts = a;
        if (isCompleted(session)) counts.completed += 1;
        else counts.abandoned += 1;
        return counts;
      }, { completed: 0, abandoned: 0 })(formSessions);
      return { name: location, ...completionCounts };
    })(Object.entries(sessionsByVisitorLocation));

    return { processedData: sessionCompletionByLocation, processedAnswer: [] };
  } catch (e) {
    if (process.env.NODE_ENV === 'development') console.log(e);
  }
};

const dataProcessor = (id) => {
  switch (id) {
    case 'funnel':
      return areachartData;
    case 'completion':
      return piechartCompletionData;
    case 'number-of-sessions':
      return barchartNumberOfSessionsData;
    case 'average-session-time':
      return barchartAverageTimeSpentData;
    case 'total-session-time':
      return barchartTotalTimeSpentData;
    case 'device':
      return piechartDeviceData;
    case 'outcome-by-device':
      return histogramDeviceCompletionData;
    case 'location':
      return barchartLoactionData;
    case 'outcome-by-location':
      return histogramVisitorLocationData;
    default:
      return () => ({ processedData: [], processedAnswer: [] });
  }
};

export default dataProcessor;
