import React from 'react';
import * as R from 'ramda';
import dataProcessor from './dataProcessor';

const summaryFlex = {
  display: 'flex',
  justifyContent: 'flex-start',
  gap: 16,
};

const summaryStyle = {
  position: 'relative',
  padding: '24px 16px 16px',
  minWidth: 200,
  maxWidth: 300,
  display: 'flex',
  minHeight: 100,
  flex: 1,
  boxShadow: '3px 2px 9px 7px #efefefb5',
  borderRadius: 16,
  justifyContent: 'flex-start',
  gap: 16,
  flexDirection: 'column',
  background: '#fff',
};

const questionAnswerWrapper = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const answerContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  fontSize: 15,
};

const SUMMARY_IDS = ['number-of-sessions', 'completion', 'funnel', 'location'];
const exampleAnswers = {
  'number-of-sessions': [{ lead: 'Total number of form starts', value: 'example answer' }],
  completion: [{ lead: 'Most likely session outcome', value: 'example answer' }],
  funnel: [{ lead: 'Least filled field', value: 'example answer' }],
  location: [{ lead: 'Most popular location', value: 'example answer' }],
};

function Summary({
  isFreePackage, charts, hasSessions, sessions, dateRange,
}) {
  return (
    <div className="summary-wrapper">
      <div style={summaryFlex}>
        {
          R.pipe(
            R.filter(({ id }) => R.includes(id, SUMMARY_IDS)),
            R.map((chart) => {
              const { id } = chart;
              const { processedAnswer } = hasSessions && dataProcessor(id)(sessions, dateRange);
              const {
                lead,
                value,
              } = R.prop(-1, (isFreePackage ? exampleAnswers[id] : processedAnswer));
              return (
                <div style={summaryStyle} key={id}>
                  <div style={questionAnswerWrapper}>
                    <h4>
                      {lead}
                    </h4>
                    <div style={answerContainerStyle}>
                      {
                        isFreePackage ? (
                          <em>
                            {value}
                          </em>
                        ) : value
                      }
                    </div>
                  </div>
                </div>
              );
            }),
          )(charts)
        }
      </div>
    </div>
  );
}

export default Summary;
