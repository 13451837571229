import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import LoginForm from '../components/forms/LoginForm';
import SignUpForm from '../components/forms/SignUpForm';

const PATHS = {
  '/sign-up': {
    component: SignUpForm,
    header: 'Be Insightful',
    subHeader: 'Already have an account?',
    redirectLink: '/login',
    redirectLabel: 'Login',
  },
  '/login': {
    component: LoginForm,
    header: 'Welcome Back!',
    subHeader: 'Don\'t have an account?',
    redirectLink: '/sign-up',
    redirectLabel: 'Sign Up',
  },
};

const logoStyle = {
  height: 60,
  width: 60,
  transform: 'translate(-20px, 0px)',
};

function Authentication() {
  const { pathname } = useLocation();
  const authType = PATHS[pathname];
  const AuthComponent = authType.component;
  return (
    <div className="main-page-wrapper p0" style={{ height: '100vh' }}>
      <Helmet>
        <title>
          {authType.header}
          {' '}
          - Insiteform
        </title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="user-data-page clearfix d-lg-flex">
        <div className="illustration-wrapper d-flex align-items-center justify-content-between flex-column">
          <div className="illustration-holder" style={{ marginTop: 60 }}>
            <img
              src="/images/assets/ils_08.svg"
              alt="illustration"
              className="illustration"
            />
            <img
              src="/images/assets/ils_08.1.svg"
              alt="illustration"
              className="shapes shape-one"
            />
            <img
              src="/images/assets/ils_08.2.svg"
              alt="illustration"
              className="shapes shape-two"
            />
          </div>
        </div>
        {/* /.illustration-wrapper  */}

        <div className="form-wrapper">
          <div className="d-flex justify-content-between">
            <div className="logo">
              <Link to="/">
                <img
                  src="/images/logo/insiteform.svg"
                  alt="logo"
                  style={logoStyle}
                />
              </Link>
            </div>
          </div>
          <div className="mt-30">
            <h2 className="font-rubik">{authType.header}</h2>
            <p className="font-rubik header-info pt-30 pb-50">
              {authType.subHeader}
              {' '}
              <Link to={authType.redirectLink}>{authType.redirectLabel}</Link>
            </p>
          </div>
          <AuthComponent />
          {/* End Signup Form */}
        </div>
        {/* /.form-wrapper */}
      </div>
      {/* /.user-data-page */}
    </div>
  );
}

export default Authentication;
