/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import {
  CircularProgress, Switch, Breadcrumbs, Typography,
} from '@mui/material';
import * as R from 'ramda';
import { useFetchDomains } from '../../hooks';
import { getCurrentUser } from '../../services/auth';
import theme from '../../theme';
import SetupForm from '../../components/forms/SetupForm';
import SavedDomains from '../../components/setup/SavedDomains';
import useAnalyticsEventTracker from '../../useAnalytics';

const buttonWrapperStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

const toggleWrapperStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

const steps = [
  'Add Your Form',
  'Install Insiteform',
  'Make Sure It Works',
];

const getAuthenticatedUser = (currentUser) => {
  if (!currentUser) return [null, null];
  const {
    authenticatedUser: {
      userId, packageName,
    },
  } = currentUser;
  return [userId, packageName];
};

function Progress() {
  return (
    <div style={{
      width: '100%', marginBottom: 40, display: 'flex', justifyContent: 'center',
    }}
    >
      <Breadcrumbs separator=">">
        {steps.map((step) => (
          <Typography
            key={step}
            sx={{
              color: theme.colors.main,
              textTransform: 'none',
            }}
            size="small"
          >
            {step}
          </Typography>
        ))}
      </Breadcrumbs>
    </div>
  );
}

function Setup() {
  const currentUser = getCurrentUser();
  const [userId, packageName] = getAuthenticatedUser(currentUser);
  const [userDomains, error, isLoading] = useFetchDomains(userId);
  const [isWPSetup, setIsWPSetup] = useState(false);
  const canAddForm = (packageName !== 'free') || (userDomains.length === 0);
  const history = useHistory();
  const track = useAnalyticsEventTracker('Setup Page');

  useEffect(() => {
    if (!currentUser) history.push('/login');
  }, [currentUser]);

  const onChangeSetup = () => {
    setIsWPSetup(!isWPSetup);
    track({ action: 'Switch', label: isWPSetup ? 'Plugin' : 'Manual' });
  };

  if (error) {
    return (
      <div className="main-page-wrapper pb-100 pt-0" style={{ minHeight: '100vh' }}>
        {error}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="main-page-wrapper pb-100 pt-0" style={{ minHeight: '100vh' }}>
        <div className="d-flex justify-content-center mt-200">
          <CircularProgress color="inherit" />
        </div>
      </div>
    );
  }

  return (
    <div className="main-page-wrapper pb-100 pt-0" style={{ minHeight: '100vh' }}>
      <Helmet>
        <title>
          Setup - Insiteform
        </title>
      </Helmet>

      <div className="fancy-hero-one">
        <div className="container">
          <div className="title-style-five text-center mb-50 md-mb-40">
            <h6 className="font-rubik">Setup</h6>
          </div>
        </div>
        <div className="bubble-one" />
        <div className="bubble-two" />
        <div className="bubble-three" />
        <div className="bubble-four" />
        <div className="bubble-five" />
        <div className="bubble-six" />
      </div>

      <div className="setup-light pb-40 md-pt-20 md-pb-20 md-mb-100">
        <div className="bubble-one" />
        <div className="bubble-two" />
        <div className="bubble-three" />
        <div className="bubble-four" />

        <div className="container">
          <Progress />
          <div className="form-style-light" style={{ fontSize: 18 }}>
            <strong>Step 1: Add Your Form</strong>
            <p>Please copy and paste the website address (URL) where your form is located into the box below.</p>
            <SetupForm userId={userId} canAddForm={canAddForm} track={track} />
          </div>
          {
            !R.isEmpty(userDomains) && (
            <>
              <div className="d-flex flex-column pb-10" style={{ fontSize: 15, paddingLeft: 8 }}>
                <div>
                  Using WordPress? -
                  {' '}
                  <span style={{ color: theme.colors.main }}>Simplify your setup with our WP plugin</span>
                </div>
                <div style={toggleWrapperStyle}>
                  Manual setup
                  <Switch
                    style={{ color: theme.colors.main }}
                    color="primary"
                    checked={isWPSetup}
                    onChange={onChangeSetup}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  Plugin setup
                </div>
              </div>
              {
               isWPSetup ? (
                 <>
                   <div className="form-style-light" style={{ fontSize: 18 }}>
                     <strong>Step 2: Add the Plugin</strong>
                     {' '}
                     <ul>
                       <li>Go to your WordPress dashboard.</li>
                       <li>
                         Find and install the
                         {' '}
                         {' '}
                         <a style={{ textDecoration: 'underline' }} target="_blank" href="https://wordpress.org/plugins/insiteform" rel="noreferrer"> Insiteform plugin</a>
                         .
                       </li>
                       <li>Activate the plugin.</li>
                     </ul>

                   </div>

                   <div className="form-style-light" style={{ fontSize: 18 }}>
                     <strong>Step 3: Activate Your Form</strong>
                     <p>
                       Copy the
                       {' '}
                       <span style={{ background: theme.colors.blueLight, padding: 4, borderRadius: 4 }}>activation key</span>
                       {' '}
                       {' '}
                       and paste it into the Insiteform plugin on your WordPress dashboard.
                     </p>

                     <div className="mixitUp-container d-flex flex-wrap mt-40">
                       <SavedDomains domains={userDomains} isWPSetup={isWPSetup} />
                     </div>
                   </div>
                 </>
               ) : (
                 <div className="form-style-light" style={{ fontSize: 18 }}>
                   <strong>Step 2: Install Code</strong>
                   {' '}
                   <p>Add this code to your website:</p>
                   <ol>
                     <li>Find the &apos;Body&apos; or &apos;Footer&apos; section in your website&apos;s HTML.</li>
                     <li>Copy the code snippet below.</li>
                     <li>Paste it into your website&apos;s code.</li>
                   </ol>
                   <div style={{ marginTop: 30 }} />
                   <div className="mixitUp-container d-flex flex-wrap">
                     <SavedDomains domains={userDomains} />
                   </div>
                 </div>
               )
              }

              <div className="form-style-light" style={{ fontSize: 18 }}>
                <strong>
                  Step
                  {' '}
                  {' '}
                  {isWPSetup ? 4 : 3}
                  : Make Sure It Works
                </strong>
                <ul>
                  <li>Fill out and submit the form on your website.</li>
                  <li>Check if you received the information.</li>
                </ul>
              </div>

              <div style={buttonWrapperStyle}>
                <button
                  type="button"
                  className="theme-btn-one"
                  onClick={() => {
                    track({ label: 'Finish Setup' });
                    history.push('/dashboard');
                  }}
                >
                  Done
                </button>
              </div>
            </>
            )
          }

        </div>
      </div>
      <div className="help-box">
        <p>If you have any questions or need help with the setup process, don&apos;t hesitate to reach out to us. Our support team is always ready to assist you.</p>
        <button type="button" className="help-button" onClick={function () { window.location.href = 'mailto:team@insiteform.com'; }}>Contact Support</button>
      </div>
    </div>
  );
}

export default Setup;
