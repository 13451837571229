import React from 'react';
import {
  PieChart, Pie, Tooltip, ResponsiveContainer, Cell, Legend, Surface,
} from 'recharts';
import theme from '../../../../theme';

const containerStyle = {
  margin: '0 0 0 15px',
  padding: 0,
  width: 'calc(70% - 30px)',
  tableLayout: 'fixed',
};

const labelStyle = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

function Dot({ fill, size }) {
  const viewBox = {
    x: 0, y: 0, width: size * 2, height: size * 2,
  };
  const svgStyle = {
    display: 'inline',
    marginRight: 0,
    marginTop: Math.round(size / 2),
  };
  return (
    <Surface width={size} height={size} viewBox={viewBox} style={svgStyle}>
      <circle stroke="none" fill={fill} cx={size} cy={size} r={size} />
    </Surface>
  );
}

function CustomLegend({ payload }) {
  return (
    <table style={containerStyle}>
      <tbody>
        {payload.map((slice) => (
          <tr key={`legend-item-${slice.payload.name}`}>
            <td style={{ width: 15 }}>
              <Dot fill={slice.payload.fill} size={9} />
            </td>
            <td style={labelStyle} title={slice.payload.name}>{slice.payload.name}</td>
            <td style={{ textAlign: 'right' }}>
              {(slice.payload.percent * 100).toFixed(1)}
              %
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default function SessionPiechart({ data }) {
  const statusColours = {
    Abandoned: theme.colors.red,
    Completed: theme.colors.green,
    Web: theme.colors.purpleLight,
    Mobile: theme.colors.greenLight,
  };

  return (
    <ResponsiveContainer width="99%">
      <PieChart>
        <Pie
          dataKey="value"
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={40}
          outerRadius={80}
        >
          {
            data.map((datum, index) => <Cell key={`key-${index}`} fill={statusColours[datum.name]} />)
          }
        </Pie>
        <Legend
          width="45%"
          layout="vertical"
          verticalAlign="middle"
          align="right"
          content={CustomLegend}
        />
        <Tooltip itemStyle={{ color: theme.colors.textLight }} />
      </PieChart>
    </ResponsiveContainer>
  );
}
