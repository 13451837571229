import React from 'react';
import {
  Accordion,
} from 'react-accessible-accordion';
import * as R from 'ramda';
import LoadingPage from '../LoadingPage';
import PaginatedSessions from './PaginatedSessions';

const sortByEntryTime = R.sort((a, b) => new Date(b.entryTime) - new Date(a.entryTime));

function FormSessions({ formSessions }) {
  if (!formSessions?.length) return <LoadingPage />;
  const sortedSessions = sortByEntryTime(formSessions);
  return (
    <div
      className="accordion-style-four"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="accordion-list-wrapper">
        <Accordion allowZeroExpanded allowMultipleExpanded>
          <PaginatedSessions
            sortedSessions={sortedSessions}
            pageLimit={10}
          />
        </Accordion>
      </div>
    </div>
  );
}

export default FormSessions;
