/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';

import LandingDark from '../views/LandingDark';
import Subscribe from '../views/inner-pages/Subscribe';
import SuccessPage from '../views/inner-pages/SubscriptionSuccess';
import Authentication from '../views/Authentication';
import PasswordReset from '../views/PasswordReset';
import TermsConditions from '../views/TermsConditions';
import Dashboard from '../views/inner-pages/Dashboard';
import Setup from '../views/inner-pages/Setup';
import Blog from '../views/Blog';
import BlogDetails from '../views/BlogDetails';
import NotFound from '../views/NotFound';
import ScrollTopBehaviour from '../components/ScrollTopBehaviour';
import { getCurrentUser } from '../services/auth';

function PrivateRoute({ component: Component, ...rest }) {
  const user = getCurrentUser();
  return (
    <Route
      {...rest}
      render={(props) => (
        user
          ? <Component {...props} />
          : <Redirect to="/login" />
      )}
    />
  );
}

function Routes() {
  return (
    <Router>
      <ScrollTopBehaviour />
      <Switch>
        <Route exact path="/" component={LandingDark} />
        <Route path="/setup" component={Setup} />
        <Route path="/(sign-up|login)/" component={Authentication} />
        <Route path="/reset-password" component={PasswordReset} />
        <Route path="/new-password/:accessToken" component={PasswordReset} />
        <Route path="/terms-conditions" component={TermsConditions} />
        <Route path="/blog" component={Blog} />
        <Route path="/(why-form-tracking|what-is-insiteform)/" component={BlogDetails} />
        <Route path="/404" component={NotFound} />

        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/packages" component={Subscribe} />
        <PrivateRoute
          path="/success/:userId/:packageName/:billingType"
          component={SuccessPage}
        />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default Routes;
