import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { Switch } from '@mui/material';
import PackageList from '../../components/subscribe/PackageList';
import PaymentModal from '../../components/subscribe/PaymentModal';
import StripeContainer from '../../components/subscribe/StripeContainer';
import { getCurrentUser } from '../../services/auth';
import theme from '../../theme';
import { subscribe } from '../../services/payment';

const billingWrapperStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};

function Subscribe() {
  const {
    authenticatedUser: {
      packageName: currentPackage,
      userId,
      email,
    },
  } = getCurrentUser();
  const [isYearlyPlan, setIsYearlyPlan] = useState(true);
  const [currency, setCurrency] = useState({ sign: '$', code: 'usd' });
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [selectedUpgrade, setSelectedUpgrade] = useState(false);
  const isSubscriptionUpgrade = currentPackage === 'tier-1';

  const notifyError = (message) => toast.error(message || 'Error', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const onCloseModal = () => {
    setSelectedPackage(null);
    setSubscription(null);
    setSelectedUpgrade(null);
  };

  const createSubscription = async (packageDetails) => {
    try {
      setSelectedPackage(packageDetails);
      const packageName = packageDetails?.packageName;
      const billingType = isYearlyPlan ? 'yearly' : 'monthly';
      const selectedCurrency = currency.code;
      subscribe(
        {
          userId, packageName, billingType, currency: selectedCurrency,
        },
        (intent) => setSubscription(intent),
        (e) => {
          notifyError(e.message);
          onCloseModal();
        },
      );
    } catch (e) {
      notifyError(e.message);
    }
  };

  const updateSubscription = (packageDetails) => {
    setSelectedPackage(packageDetails);
    setSelectedUpgrade(true);
  };

  const onSelectPackage = (packageDetails) => {
    if (!isSubscriptionUpgrade) createSubscription(packageDetails);
    else updateSubscription(packageDetails);
  };

  const onChangePlan = () => setIsYearlyPlan(!isYearlyPlan);

  const getGeoInfo = () => {
    const locale = Intl?.DateTimeFormat()?.resolvedOptions()?.locale;
    if (locale === 'en-GB') setCurrency({ sign: '£', code: 'gbp' });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Packages - Insiteform
        </title>
      </Helmet>
      <div className="pricing-section-three-inner-page mb-200 md-mb-120">
        <img
          src="/images/shape/124.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="/images/shape/125.svg"
          alt="shape"
          className="shapes shape-two"
        />

        <div className="container">
          <div className="title-style-five text-center mb-150 md-mb-40">
            <h6 className="font-rubik">Packages</h6>
          </div>
          <div className="pricing-table-area-three">
            <div style={billingWrapperStyle}>
              Monthly
              <Switch
                style={{ color: theme.colors.main }}
                color="primary"
                checked={isYearlyPlan}
                onChange={onChangePlan}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              Yearly
              <div style={{ color: theme.colors.main, marginLeft: 8 }}>(Save -30%)</div>
            </div>
            <PackageList
              currentPackage={currentPackage}
              isYearly={isYearlyPlan}
              currency={currency}
              onSelectPackage={onSelectPackage}
            />
          </div>
          {/* /.pricing-table-area-three */}
        </div>
        <PaymentModal
          modalIsOpen={!!selectedPackage}
          closeModal={onCloseModal}
        >
          <StripeContainer
            selectedPackage={selectedPackage}
            selectedUpgrade={selectedUpgrade}
            subscription={subscription}
            userId={userId}
            userEmail={email}
            isYearly={isYearlyPlan}
            closeModal={onCloseModal}
          />
        </PaymentModal>
      </div>
      {/* /.pricing-section-three */}
    </div>
  );
}

export default Subscribe;
