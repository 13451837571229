import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function PaymentModal({ modalIsOpen, closeModal, children }) {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Subscribe"
      className="custom-modal  modal-contact-popup-one subscribe"
      overlayClassName="custom-overlay"
      closeTimeoutMS={500}
    >
      {children}
    </Modal>
  );
}

export default PaymentModal;
