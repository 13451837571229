/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { CircularProgress } from '@mui/material';
import PriceSummary from './PriceSummary';
import { updatePackage } from '../../services/payment';

function CheckoutForm({
  userEmail, userId, selectedPackage, isYearly, selectedUpgrade,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const packageName = selectedPackage?.packageName;
  const displayPrice = selectedPackage?.displayPrice;
  const packageLabel = selectedPackage?.label;
  const currencySign = selectedPackage?.currency.sign;
  const currency = selectedPackage?.currency.code;

  const frequency = isYearly ? 'Yearly' : 'Monthly';
  const pricePerPeriod = isYearly ? displayPrice * 12 : displayPrice;
  const billingType = frequency?.toLocaleLowerCase() ?? 'yearly';

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setError(null);
    setIsLoading(true);

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_PUBLIC_URL}/success/${userId}/${packageName}/${billingType}`,
        receipt_email: userEmail,
      },
    });
    setIsLoading(false);
    if (result.error) {
      setError(result.error.message);
    }
  };

  const upgradeSubscription = () => {
    try {
      setIsLoading(true);
      updatePackage(
        {
          userId, packageName, billingType, currency,
        },
        () => history.push(`/success/${userId}/${packageName}/${billingType}`),
        (e) => setError(e.message),
      );
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <div className="form-style-light mb-0" style={{ borderRadius: 20, padding: 0 }}>

      <div className="user-data-page clearfix d-lg-flex">

        <div className="illustration-wrapper subscribe-illustrate d-flex align-items-center flex-column">
          <PriceSummary
            currency={currencySign}
            frequency={frequency}
            pricePerPeriod={pricePerPeriod}
            isUpgrade={selectedUpgrade}
          />

          <div className="illustration-holder" style={{ marginTop: 60 }}>
            <img
              src="/images/assets/ils_08.svg"
              alt="illustration"
              className="illustration"
            />
            <img
              src="/images/assets/ils_08.1.svg"
              alt="illustration"
              className="shapes shape-one"
            />
            <img
              src="/images/assets/ils_08.2.svg"
              alt="illustration"
              className="shapes shape-two"
            />
          </div>
        </div>
        {/* /.illustration-wrapper  */}

        <div className="form-wrapper" style={{ textAlign: 'center' }}>
          <div>
            <p className="header-info pt-30 pb-50">
              {packageLabel}
            </p>
          </div>

          <PriceSummary
            className="tablet-cto mb-40"
            currency={currencySign}
            frequency={frequency}
            pricePerPeriod={pricePerPeriod}
            isUpgrade={selectedUpgrade}
          />

          {
            !!selectedPackage && !selectedUpgrade ? (
              <form onSubmit={handleSubmit} className="user-data-form">
                <PaymentElement />
                { error && <div className="invalid-feedback">{error}</div>}
                <div className="col-12">
                  <button disabled={!stripe} type="submit" className="theme-btn-one mt-30 mb-30">
                    { isLoading ? (
                      <div className="d-flex justify-content-center" style={{ padding: 8 }}>
                        <CircularProgress color="inherit" />
                      </div>
                    ) : 'Activate Package' }
                  </button>
                </div>
              </form>

            ) : (
              <form className="user-data-form">
                { error && <div className="invalid-feedback">{error}</div>}
                <div className="col-12">
                  <button type="button" className="theme-btn-one mt-50 mb-50" onClick={upgradeSubscription}>
                    { isLoading ? (
                      <div className="d-flex justify-content-center" style={{ padding: 8 }}>
                        <CircularProgress color="inherit" />
                      </div>
                    ) : 'Upgrade Now' }
                  </button>
                </div>
              </form>
            )
          }
        </div>
      </div>
    </div>
  );
}
export default CheckoutForm;
