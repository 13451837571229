import React from 'react';
import {
  BarChart,
  Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import theme from '../../../../theme';

const getDataKeys = (exampleData) => {
  const dataKeys = Object.entries(exampleData[0]);
  const xAxisKey = dataKeys.map(([key, value]) => (
    typeof value === 'number' ? key : null)).filter(Boolean);
  const yAxisKey = dataKeys.map(([key, value]) => (
    typeof value !== 'number' ? key : null)).filter(Boolean);
  return [xAxisKey[0], yAxisKey[0]];
};

export default function VerticalBarchart({
  data, axisLabel, unit, fill, dataName,
}) {
  const [xAxisKey, yAxisKey] = getDataKeys(data);
  return (
    <ResponsiveContainer width="99%">
      <BarChart
        layout="vertical"
        data={data}
        margin={{
          top: 30,
          bottom: 20,
          left: 60,
        }}
      >
        <CartesianGrid key="grid" horizontal={false} />
        <XAxis
          allowDecimals={false}
          axisLine={false}
          tickLine={false}
          type="number"
          style={{ fontSize: 15 }}
          label={{
            value: axisLabel.toUpperCase(),
            position: 'bottom',
            offset: 8,
            fontSize: 11,
            fill: theme.colors.textLight,
          }}
        />
        <YAxis
          dataKey={yAxisKey}
          type="category"
          style={{ fontSize: 15 }}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip />
        <Bar
          dataKey={xAxisKey}
          fill={fill}
          name={dataName}
          unit={unit}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
