import React from 'react';
import { Link } from 'react-router-dom';

function FooterAlt() {
  return (
    <div className="row justify-content-center align-items-center">
      <div className="col-lg-4">
        <div className="logo">
          <a href="index.html">
            <img src="/images/logo/insiteform.svg" alt="logo" style={{ height: 75, width: 75 }} />
          </a>
        </div>
      </div>
      {/* End .col */}

      <div className="col-lg-4">
        <div className="title">Find out more</div>

        <div className="row justify-content-center align-items-center">
          <a href="https://twitter.com/insiteform" target="_blank" rel="noreferrer" style={{ marginRight: 16 }}>
            <i className="fa fa-twitter" />
          </a>
          <Link to="/blog">Blog</Link>
        </div>
      </div>
      {/* End .col */}

      <div className="col-lg-4">
        <div className="title">Get in touch</div>
        <div className="text-center">
          <a href="mailto:team@insiteform.com" className="email">
            team@insiteform.com
          </a>
        </div>
      </div>
      {/* End .col */}
    </div>
  );
}

export default FooterAlt;
