import React from 'react';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import FormTable from './FormTable';
import theme from '../../../theme';
import { isCompleted } from '../analytics/utils';

const headerTextWrapperStyle = {
  display: 'flex',
  gap: 8,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginRight: 32,
};

const completionStatusWrapperStyle = {
  display: 'flex',
  gap: 8,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const completionIndicatorStyle = {
  height: 8,
  width: 8,
  border: '1px solid #d6d6d6',
  borderRadius: 8,
  marginLeft: 4,
};

const rowItemWrapperStyle = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
};

const deviceTagWrapper = {
  padding: 8,
  border: '1px solid #d6d6d6',
  borderRadius: 8,
};

const referrerTagWrapper = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 4,
  padding: 8,
  border: '1px solid #d6d6d6',
  borderRadius: 8,
};

const getDate = (entry) => {
  const date = moment(Date.parse(entry));

  if (moment().isSame(date, 'day')) return 'Today';
  if (moment().subtract(1, 'day').isSame(date, 'day')) return 'Yesterday';
  return date.format('Do MMM YY');
};

const getTime = (entry, exit) => {
  const entryTime = moment(Date.parse(entry)).format('h:mm A');
  const exitTime = moment(Date.parse(exit)).format('h:mm A');
  return (
    <div className="display-tab">
      {entryTime}
      {' '}
      -
      {' '}
      {exitTime}
    </div>
  );
};

const getDevice = (isMobile) => (
  <div style={deviceTagWrapper}>
    {isMobile ? 'mobile' : 'web'}
  </div>
);

const getCompletionState = (formData, exitTime) => {
  const formCompleted = isCompleted(formData);

  const incompleteStateOptions = {
    abandoned: { text: 'Abandoned', color: theme.colors.red },
    inprogress: { text: 'In progress...', color: theme.colors.yellow },
  };
  const exit = moment(Date.parse(exitTime));
  const incompleteState = moment().diff(exit, 'minutes') >= 30 ? 'abandoned' : 'inprogress';
  return (
    <div style={completionStatusWrapperStyle}>
      <div style={{
        ...completionIndicatorStyle,
        background: formCompleted
          ? theme.colors.green : incompleteStateOptions[incompleteState].color,
      }}
      />
      {formCompleted ? 'Completed' : incompleteStateOptions[incompleteState].text}
    </div>
  );
};

const referrerBox = (formReferrer) => {
  const referrer = formReferrer || 'Not found';
  return (
    <div className="display-tab">
      <Tooltip placement="top" title={(<em style={{ fontSize: 13 }}>{referrer}</em>)}>
        <div style={referrerTagWrapper}>
          Source
          <i className="fa fa-info-circle" aria-hidden="true" />
        </div>
      </Tooltip>
    </div>
  );
};

function HeaderText({ session }) {
  return (
    <div style={headerTextWrapperStyle}>
      <div style={rowItemWrapperStyle}>
        {getDate(session.entryTime)}
      </div>
      <div style={rowItemWrapperStyle}>
        {getTime(session.entryTime, session.exitTime)}
      </div>
      <div style={rowItemWrapperStyle}>
        {getDevice(session.isMobile)}
      </div>
      <div style={rowItemWrapperStyle}>
        {getCompletionState(session.formData, session.exitTime)}
      </div>
      <div style={rowItemWrapperStyle}>
        <div className="display-tab">
          {session.visitorRegion}
        </div>
      </div>
      <div style={rowItemWrapperStyle}>
        {referrerBox(session.formReferrer)}
      </div>
    </div>
  );
}

export default function Session({ session }) {
  return (
    <div>
      <AccordionItem className="card" uuid={session.sessionId}>
        <AccordionItemHeading className="card-header">
          <AccordionItemButton>
            <h6 className="mb-0">
              <button type="button" className="btn btn-link">
                <HeaderText session={session} />
              </button>
            </h6>
          </AccordionItemButton>
        </AccordionItemHeading>

        <AccordionItemPanel className="card-body fadeInUp">
          <FormTable userForm={session.formData} />
        </AccordionItemPanel>
      </AccordionItem>
    </div>
  );
}
