import React from 'react';

const listStyle = {
  margin: '16px 0',
  paddingLeft: 16,
  listStyleType: 'disc',
};

export default function WhatInsiteform() {
  return (
    <div className="post-meta">
      <img
        src="/images/blog/form-table.png"
        alt="media post"
        className="image-meta"
      />
      {/* <div className="tag">01 Jan. 2023</div> */}
      <h1 className="title">
        Insiteform: The Essential Form Analytics & Tracking Solution
      </h1>
      <p>
        Are you looking for a way to boost your website&apos;s
        conversion rate and gain valuable insights into your customers&apos;
        behaviour? Insiteform may be the solution you need.
      </p>
      <p>
        Insiteform is a powerful tool offering in-depth analytics and data visualisations
        to swiftly identify problematic
        fields and formulate strategies for optimising form conversions.
      </p>
      <img
        src="/images/blog/analytics.png"
        alt="media post"
        className="image-meta mt-35"
      />
      <div className="mark-text">
        Benefits of using Insiteform include:
      </div>
      <ul style={listStyle}>
        <li>
          Streamlined form analytics
        </li>
        <li>
          Enhanced data visualisation
        </li>
        <li>
          Quick identification of problematic fields
        </li>
        <li>
          Ability to track multiple forms for various use-cases from a single dashboard
        </li>

        <li>
          Easy formulation of strategies for optimising form conversions
        </li>
      </ul>
      <p>
        If you&apos;re looking to boost your website&apos;s conversion rate,
        Insiteform is a powerful tool worth considering.
        So why wait? Give it a try today!
      </p>
    </div>
  );
}
