import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import HeroBannerAlt from '../components/hero-banner/HeroBannerAlt';
import FeatureSlider from '../components/block-slider/FeatureSlider';
import FooterAlt from '../components/footer/FooterAlt';
import FormBrands from '../components/brand/FormBrands';
import Pricing from '../components/pricing/PricingList';
import useAnalyticsEventTracker from '../useAnalytics';

function ProductLandingDark() {
  const track = useAnalyticsEventTracker('Landing');
  return (
    <div className="main-page-wrapper dark-style lg-container p0 pl-4 pr-4">
      <Helmet>
        <title>Insiteform - Form analytics & tracking</title>
        <meta
          name="keywords"
          content="hubspot forms, analytics form, form analysis, wordpress forms, webflow forms, form analytics, form tracking, form optimisation, form performance, form conversion rate, form data analysis, form completion rate, form user experience, form optimisation tools"
        />
        <meta
          name="description"
          content="Insiteform is a powerful tool for tracking and improving the performance of your forms. With its analytics and tracking features, you can increase your form's conversion rate and boost conversions."
        />
      </Helmet>
      <Header track={track} />
      <HeroBannerAlt track={track} />
      <div className="fancy-feature-thirteen pt-100 md-pt-80 " id="features">
        <div className="container">
          <div className="sldier-wrapper  mt-30 md-mt-50">
            <FeatureSlider />
          </div>
        </div>
      </div>

      <div className="row pt-200 pl-2 pr-2 pb-120 md-pb-50">
        <div className="col-xl-8 col-lg-11 col-md-10 m-auto">
          <div className="title-style-six text-center">
            <h4 className="pr-1 pl-1">
              Compatible with these form providers and more
            </h4>
          </div>
        </div>
      </div>
      <div className="useable-tools-section-three pb-200 md-pb-80">
        <div className="logo-wrapper d-flex flex-wrap justify-content-center align-items-center">
          <FormBrands />
        </div>
      </div>

      <div
        className="pricing-section-five pt-250 md-mt-150 sm-mt-80 md-pt-80"
        id="pricing"
      >
        <div className="container">
          <div className="flex-column align-items-center">
            <div className="row justify-content-center mb-30">
              <div className="title-style-six pl-5 md-p0">
                <h6 style={{ fontSize: '15px' }}>Pricing</h6>
              </div>
            </div>
            <div className="pricing-table-area-five md-mt-60">
              <Pricing />
            </div>
          </div>
        </div>
      </div>

      <div className="fancy-feature-thirteen pt-250 md-pt-110 ">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-11 col-md-10 m-auto">
              <div className="title-style-six text-center">
                <h2>
                  Learn More About Form
                  {' '}
                  <span>Analytics</span>
                </h2>
                <ul className="mt-30">
                  <li>
                    <p className="text-xs">
                      1.
                      {' '}
                      <Link to="/why-form-tracking">
                        Why should you track and analyse your website forms?
                      </Link>
                    </p>
                  </li>
                  <li>
                    <p className="text-xs">
                      2.
                      {' '}
                      <Link to="/what-is-insiteform">What is Insiteform?</Link>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*= ====================================================
					Footer
				===================================================== */}
      <footer className="theme-footer-six">
        <div className="inner-container">
          <div className="container">
            <FooterAlt />
            <p className="copyright">
              {' '}
              Copyright @
              {new Date().getFullYear()}
              {' '}
              Insiteform
            </p>
          </div>
        </div>
        {/*  /.inner-container */}
      </footer>
      {/* /.theme-footer-six */}
    </div>
  );
}

export default ProductLandingDark;
