import React from 'react';

const gifStyle = {
  height: 200,
  width: 200,
  borderRadius: 16,
  marginBottom: 16,
};

export default function LoadingPage() {
  return (
    <>
      <img
        src="https://media.giphy.com/media/l2JhtCMwgCLcRXmgg/giphy.gif"
        alt="waiting"
        style={gifStyle}
        loading="lazy"
      />
      <h3>Your insights are on the way!</h3>
    </>
  );
}
