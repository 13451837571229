/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { login } from '../../services/auth';

function LoginForm() {
  const [networkError, setNetworkError] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const history = useHistory();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Entered value does not match email format'),
    password: Yup.string().required('Password is required'),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit({ email, password }) {
    login(
      email,
      password,
      () => history.push('/dashboard'),
      (error) => {
        const { code, message, response } = error;
        const errorMessage = response.data || `${code} - ${message}`;
        setNetworkError(errorMessage);
        setTimeout(() => setNetworkError(null), 5000);
        if (process.env.NODE_ENV === 'development') console.log(error);
      },
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="user-data-form">
      <div className="row">
        <div className="col-12">
          <div className="input-group-meta mb-80 sm-mb-70">
            <label>Email</label>
            <input
              placeholder="Enter Your Email"
              name="email"
              type="text"
              {...register('email')}
              className={` ${errors.email ? 'is-invalid' : ''}`}
            />
            {errors.email && (
            <div className="invalid-feedback">{errors.email?.message}</div>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="input-group-meta mb-25">
            <label>Password</label>
            <input
              placeholder="Enter Password"
              name="password"
              type={passwordShown ? 'text' : 'password'}
              {...register('password')}
              className={` ${errors.password ? 'is-invalid' : ''}`}
            />
            {errors.password && (
            <div className="invalid-feedback">
              {errors.password?.message}
            </div>
            )}
            <span
              className="placeholder_icon"
              onClick={togglePasswordVisiblity}
            >
              <span
                className={
                    passwordShown ? 'passVicon eye-slash' : 'passVicon'
                  }
              >
                <img src="/images/icon/view.svg" alt="ico" />
              </span>
            </span>
          </div>
        </div>

        <div className="col-12">
          {networkError && (
          <div className="invalid-feedback">
            {networkError}
          </div>
          )}
        </div>

        <div className="col-12">
          <button type="submit" className="theme-btn-one mt-50 mb-50">Login</button>
        </div>
        <div className="col-12">
          <div className="agreement-checkbox d-flex justify-content-between align-items-center">
            <Link to="/reset-password">Forgot your password?</Link>
          </div>
        </div>
      </div>
    </form>
  );
}

export default LoginForm;
