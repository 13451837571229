import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function FeatureSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const settings = {
    slidesToShow: 3,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3500,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider
        {...settings}
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        swipeToSlide
        focusOnSelect
        className="slick-nav-custom"
      >
        <div className="d-flex item_content">
          <div className="icon d-flex align-items-center justify-content-center">
            <img src="/images/icon/81.svg" alt="icon" />
          </div>
          <p>Integrate Insiteform with your website&apos;s forms</p>
        </div>
        {/* /.d-flex */}
        <div className="d-flex item_content">
          <div className="icon d-flex align-items-center justify-content-center">
            <img src="/images/icon/81.svg" alt="icon" />
          </div>
          <p>Monitor form submissions from your personalised dashboard</p>
        </div>
        {/* /.d-flex */}
        <div className="d-flex item_content">
          <div className="icon d-flex align-items-center justify-content-center">
            <img src="/images/icon/81.svg" alt="icon" />
          </div>
          <p>
            Identify and capture missed opportunities
            {' '}
            <br />
            with precision
          </p>
        </div>
        {/* /.d-flex */}
      </Slider>
      {/* Bottom text slide */}

      <div className="position-relative">
        <Slider
          className="slick-nav-none carousel-inner"
          asNavFor={nav2}
          fade
          speed={600}
          ref={(slider1) => setNav1(slider1)}
        >
          <div className="carousel-item">
            <img src="/images/assets/screenly-comp.png" alt="feature" />

          </div>
          <div className="carousel-item">
            <img src="/images/assets/screenly-splash.png" alt="feature" />

          </div>
          <div className="carousel-item">
            <img src="/images/assets/screenly-graph.png" alt="feature" />
          </div>
        </Slider>
        {/* Top Image slide */}
        <img
          src="/images/shape/152.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="/images/shape/153.png"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
    </>
  );
}
