import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import theme from '../../theme';

function HeaderSignpost({
  activeSidebarGroup, setEditModalOpen, activeDomain, editDisabled,
}) {
  const onClick = editDisabled ? null : () => setEditModalOpen('domain');
  return (
    <>
      <div className="mobile-signpost font-rubik text-uppercase">
        {activeSidebarGroup}
      </div>
      <i className="mobile-signpost fa fa-chevron-right" aria-hidden="true" />
      <Tooltip disableHoverListener={editDisabled} title={<Typography>Edit website/forms</Typography>} placement="right">
        <button
          {...(editDisabled && { style: { color: theme.colors.textLight } })}
          type="button"
          className="edit-btn font-rubik text-uppercase "
          onClick={onClick}
        >
          {activeDomain?.domainName}
          <i
            className="fa fa-pencil-square-o"
            aria-hidden="true"
            style={{ fontSize: 24 }}
          />
        </button>
      </Tooltip>
    </>
  );
}

export default HeaderSignpost;
