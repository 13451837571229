import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import ResetRequest from '../components/forms/RequestReset';
import SaveReset from '../components/forms/SaveReset';

const PATH = {
  '/reset-password': {
    component: ResetRequest,
    header: 'Reset Password',
  },
};

const logoStyle = {
  height: 60,
  width: 60,
  transform: 'translate(-20px, 0px)',
};

function PasswordReset() {
  const { pathname } = useLocation();
  const authType = PATH[pathname] ?? {
    component: SaveReset,
    header: 'New Password',
  };
  const AuthComponent = authType.component;
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          {authType.header}
          {' '}
          - Insiteform
        </title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="user-data-page clearfix d-lg-flex">
        <div className="illustration-wrapper d-flex align-items-center justify-content-between flex-column">
          <div className="illustration-holder" style={{ marginTop: 60 }}>
            <img
              src="/images/assets/ils_08.svg"
              alt="illustration"
              className="illustration"
            />
            <img
              src="/images/assets/ils_08.1.svg"
              alt="illustration"
              className="shapes shape-one"
            />
            <img
              src="/images/assets/ils_08.2.svg"
              alt="illustration"
              className="shapes shape-two"
            />
          </div>
        </div>
        {/* /.illustration-wrapper  */}
        <div className="form-wrapper">
          <div className="d-flex justify-content-between">
            <div className="logo">
              <Link to="/">
                <img
                  src="/images/logo/insiteform.svg"
                  alt="logo"
                  style={logoStyle}
                />
              </Link>
            </div>

          </div>
          <div className="mt-30 mb-30">
            <h2 className="font-rubik">{authType.header}</h2>
          </div>
          <AuthComponent />
          {/* End Signup Form */}
        </div>
        {/* /.form-wrapper */}
      </div>
      {/* /.user-data-page */}
    </div>
  );
}

export default PasswordReset;
