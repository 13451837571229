/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { CircularProgress } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './Payment';

const testKey = 'pk_test_51MGIjwB7xLP71j19OcIqqnM4pbE04arljdBtYqm3aGG2aMZalDveVuSdeRp2BAUFFy82FKJQN5UY5tMnWqP9Kdp900ltpgk18X';
const liveKey = 'pk_live_51MGIjwB7xLP71j19g17EOY9mkrijBN6Glyf0Ns2HlPs4Z8sJIrCPlXYU6iuICnlzh8DMh0c4ZRiVPfCvCkzjuOQB00CHSSXtw8';
const PUBLIC_KEY = process.env.NODE_ENV === 'development' ? testKey : liveKey;
const stripeTestPromise = loadStripe(PUBLIC_KEY);

function StripeContainer({
  subscription, selectedUpgrade, ...rest
}) {
  const options = {
    clientSecret: subscription?.clientSecret,
  };
  if (subscription || selectedUpgrade) {
    return (
      <Elements stripe={stripeTestPromise} options={options}>
        <PaymentForm
          selectedUpgrade={selectedUpgrade}
          {...rest}
        />
      </Elements>
    );
  }
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: 500 }}>
      <CircularProgress color="inherit" />
    </div>
  );
}

export default StripeContainer;
