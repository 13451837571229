/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const API_REQUEST_RESET_URL = `${process.env.REACT_APP_API_BASE_URL}/password-reset/request`;
const API_SET_NEW_URL = `${process.env.REACT_APP_API_BASE_URL}/password-reset/new-password`;

const requestReset = ({
  email,
}, next, error) => axios.post(`${API_REQUEST_RESET_URL}`, { email }).then(() => {
  next();
}).catch((e) => error(e));

const setNewPassword = ({
  accessToken,
  newPassword,
}, next, error) => axios.post(`${API_SET_NEW_URL}`, {
  accessToken,
  newPassword,
}).then(() => {
  next();
}).catch((e) => error(e));

export {
  requestReset,
  setNewPassword,
};
