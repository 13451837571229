import axios from 'axios';
import uuid from 'react-uuid';

export const STORAGE_KEY = 'insiteform_user';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/authenticate`;

const login = (email, password, next, error) => axios.post(`${API_URL}/login`, {
  email,
  password,
}).then(({ data }) => {
  if (data.accessToken) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
    next();
  }
}).catch((e) => error(e));

const register = (name, email, password, next, error) => {
  axios.post(`${API_URL}/register`, {
    userId: uuid(),
    name,
    email,
    password,
  }).then(() => login(email, password, next, (e) => error(e))).catch((e) => error(e));
};

const refresh = () => window.location.reload();

const logout = () => {
  localStorage.removeItem(STORAGE_KEY);
  refresh();
};

const getCurrentUser = () => {
  const authenticatedUser = JSON.parse(localStorage.getItem(STORAGE_KEY));
  return authenticatedUser;
};

export {
  register,
  login,
  logout,
  refresh,
  getCurrentUser,
};
