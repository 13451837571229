/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import {
  ProSidebar,
  SidebarHeader,
  Menu,
  MenuItem,
  SidebarContent,
} from 'react-pro-sidebar';
import theme from '../../theme';
import { logout } from '../../services/auth';

const logoStyle = {
  height: 60,
  width: 60,
  transform: 'translate(-20px, 0px)',
};

function MegaMenuMobile({ userName }) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <div className="mega-menu-wrapper">
      <div className="mob-header multi-mob-header">
        <button type="button" className="toggler-menu" onClick={handleClick}>
          <div className={click ? 'active' : ''}>
            <span />
            <span />
            <span />
          </div>
        </button>
      </div>
      {/* End Header */}

      <ProSidebar
        className={click ? 'mega-mobile-menu menu-open' : 'mega-mobile-menu'}
      >
        <SidebarHeader>
          <div className="logo position-static">
            <a href="/">
              <img
                src="/images/logo/insiteform.svg"
                alt="logo"
                style={logoStyle}
              />
            </a>
          </div>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="/images/icon/close-w.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}

          {/* End logo */}
        </SidebarHeader>
        <SidebarContent>
          <Menu>
            {/* End Docs SubMenu */}
            {
              userName
                ? (
                  <>
                    <MenuItem>
                      <button type="button" onClick={logout} style={{ color: theme.colors.main }}>
                        Not
                        {' '}
                        {userName}
                        ? - Log out
                      </button>
                    </MenuItem>
                    <MenuItem>
                      <Link
                        to="/dashboard"
                        className="signUp-action d-flex align-items-center"
                      >
                        Dashboard
                      </Link>
                    </MenuItem>
                  </>
                )
                : (
                  <>
                    <MenuItem>
                      <Link to="login">Login</Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to="sign-up">Get Started</Link>
                    </MenuItem>
                  </>
                )
            }
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
}

export default MegaMenuMobile;
