import moment from 'moment';

const everyRequiredFilled = (f) => f
  .filter(({ required }) => required === 'true')
  .every(({ visitorInteraction }) => visitorInteraction);

const everyFieldFilled = (f) => f.every(({ visitorInteraction }) => visitorInteraction);
const sumbitClicked = (f) => f.some(({ fieldName, fieldType, visitorInteraction }) => (fieldType === 'submit' && visitorInteraction)
|| (fieldName?.toLowerCase() === 'submit' && visitorInteraction));

export const getAverage = (value, length) => parseInt((value / length).toFixed(), 10);

export const sortByFieldId = (fields) => {
  const regex = /\d+/g;
  return fields.map(({ id, fieldName, ...rest }) => ({
    id: id?.match(regex)?.join('') ?? id, fieldName,
  })).sort((a, b) => a.id - b.id);
};

export const isCompleted = (formData) => everyRequiredFilled(formData)
&& (everyFieldFilled(formData) || sumbitClicked(formData));

export const generateDaysOfWeek = ({ startDate, endDate }) => [
  ...Array(moment(endDate).diff(moment(startDate), 'days') + 1).keys(),
].map((i) => moment(startDate).add(i, 'days').format('Do MMM YY'));
