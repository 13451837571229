import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga4';
import Routes from './router/Routes';
import ScrollToTop from './components/ScrollToTop';
import RollbarContainer from './Rollbar';
import 'aos/dist/aos.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.initialize([
        {
          trackingId: process.env.REACT_APP_GA,
        },
      ]);
    }
  }, []);
  window.addEventListener('load', AOS.refresh);
  return (
    <>
      <Helmet>
        <title>Insiteform - Form analytics & tracking</title>
        <meta property="og:site_name" content="Insiteform" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.insiteform.com/" />
        <meta
          property="og:title"
          content="Insiteform: Form analytics & tracking"
        />
        <meta
          name="keywords"
          content="webflow forms, wordpress forms, webflow forms, form analytics, form tracking, form optimisation, form performance, form conversion rate, form data analysis, form completion rate, form user experience, form optimisation tools"
        />
        <meta
          name="description"
          content="Insiteform is a powerful tool for tracking and improving the performance of your forms. With its analytics and tracking features, you can increase your form's conversion rate and boost conversions."
        />
      </Helmet>
      {/* End Seo Helmt */}

      <ScrollToTop />
      <ToastContainer />
      <RollbarContainer>
        <Routes />
      </RollbarContainer>
    </>
  );
}

export default App;
