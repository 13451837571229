import React from 'react';

const BrandImages = [
  { slug: 'ninja-forms', title: 'Ninja Forms' },
  { slug: 'wp-forms', title: 'WP Forms' },
  { slug: 'hubspot', title: 'Hubspot' },
  { slug: 'webflow', title: 'Webflow' },
  { slug: 'formidable-forms', title: 'Formidable Forms' },
  { slug: 'contact-form', title: 'Contact Form 7' },
];

function FormBrands() {
  return (
    <>
      {BrandImages.map(({ slug, title }, i) => (
        <div
          title={title}
          className="logo d-flex align-items-center justify-content-center"
          key={i}
        >
          <img src={`images/logo/${slug}.png`} alt="logo" />
        </div>
      ))}
    </>
  );
}

export default FormBrands;
