/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { register as registerUser } from '../../services/auth';

function SignUpForm() {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const [rePasswordShown, setRePasswordShown] = useState(false);
  const toggleRePasswordVisiblity = () => {
    setRePasswordShown(!rePasswordShown);
  };

  const history = useHistory();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Entered value does not match email format'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
    honeypottter: Yup.string(),
  });

  const notify = (message) => toast.error(message || 'Error', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit({
    name, email, password, honeypottter,
  }) {
    if (honeypottter) {
      return;
    }
    registerUser(
      name,
      email,
      password,
      () => {
        history.push('/setup');
      },
      (error) => {
        const { code, message, response } = error;
        const errorMessage = response.data || `${code} - ${message}`;
        notify(errorMessage);
        if (process.env.NODE_ENV === 'development') console.log(error);
      },
    );
  }

  return (
    <form className="user-data-form " onSubmit={handleSubmit(onSubmit)}>
      <input
        name="honeypottter"
        placeholder="do not fill this"
        type="hidden"
        {...register('honeypottter')}
      />
      <div className="row">
        <div className="col-12">
          <div className="input-group-meta mb-50">
            <label>Name</label>
            <input
              placeholder="Enter Your Name"
              name="name"
              type="text"
              {...register('name')}
              className={` ${errors.email ? 'is-invalid' : ''}`}
            />

            {errors.name && (
            <div className="invalid-feedback">{errors.name?.message}</div>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="input-group-meta mb-50">
            <label>Email</label>
            <input
              placeholder="Enter Your Email"
              name="email"
              type="text"
              {...register('email')}
              className={` ${errors.email ? 'is-invalid' : ''}`}
            />

            {errors.email && (
            <div className="invalid-feedback">{errors.email?.message}</div>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="input-group-meta mb-50">
            <label>Password</label>
            <input
              placeholder="Enter Password"
              name="password"
              type={passwordShown ? 'text' : 'password'}
              {...register('password')}
              className={` ${errors.password ? 'is-invalid' : ''}`}
            />
            {errors.password && (
            <div className="invalid-feedback">
              {errors.password?.message}
            </div>
            )}
            {/* End error msg */}
            <span
              className="placeholder_icon"
              onClick={togglePasswordVisiblity}
            >
              <span
                className={
                    passwordShown ? 'passVicon eye-slash' : 'passVicon'
                  }
              >
                <img src="/images/icon/view.svg" alt="" />
              </span>
            </span>
            {/* End placeholder icon */}
          </div>
        </div>
        <div className="col-12">
          <div className="input-group-meta mb-25">
            <label>Re-type Password</label>
            <input
              placeholder="Enter Password"
              name="confirmPassword"
              type={rePasswordShown ? 'text' : 'password'}
              {...register('confirmPassword')}
              className={` ${errors.confirmPassword ? 'is-invalid' : ''}`}
            />
            {errors.confirmPassword && (
            <div className="invalid-feedback">
              {errors.confirmPassword?.message}
            </div>
            )}
            {/* End error msg */}
            <span
              className="placeholder_icon"
              onClick={toggleRePasswordVisiblity}
            >
              <span
                className={
                    rePasswordShown ? 'passVicon eye-slash' : 'passVicon'
                  }
              >
                <img src="/images/icon/view.svg" alt="" />
              </span>
            </span>
            {/* End placeholder icon */}
          </div>
        </div>

        <div className="col-12">
          <div className="agreement-checkbox d-flex justify-content-between align-items-center sm-mt-10">
            <div className="position-relative">
              By proceeding, you are agreeing to Insiteform&apos;s
              {' '}
              <Link to="/terms-conditions">
                Terms of service
              </Link>
              {' '}
              &#38;
              {' '}
              <Link to="/terms-conditions">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12">
          <button type="submit" className="theme-btn-one mt-30 mb-50">
            Sign Me Up
          </button>
        </div>
        {/* End .col */}
      </div>
    </form>
  );
}

export default SignUpForm;
