/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { requestReset } from '../../services/reset';

function ResetRequest() {
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Entered value does not match email format'),
  });

  const notifySuccess = () => toast.success('Done', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const notify = (message) => toast.error(message || 'Error', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit({ email }) {
    requestReset(
      { email },
      () => {
        notifySuccess();
        setTimeout(() => history.push('/'), 2000);
      },
      (error) => {
        const { message, response } = error;
        const errorMessage = response.data || message;
        notify(errorMessage);
        if (process.env.NODE_ENV === 'development') console.log(error);
      },
    );
  }

  return (
    <form className="user-data-form " onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12">
          <div className="input-group-meta mb-50">
            <label>Email</label>
            <input
              placeholder="Enter Your Email"
              name="email"
              type="text"
              {...register('email')}
              className={` ${errors.email ? 'is-invalid' : ''}`}
            />

            {errors.email && (
            <div className="invalid-feedback">{errors.email?.message}</div>
            )}
          </div>
        </div>
        <div className="col-12">
          <button type="submit" className="theme-btn-one mt-30 mb-50">
            Reset My Password
          </button>
        </div>
        {/* End .col */}
      </div>
    </form>
  );
}

export default ResetRequest;
