/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import authHeader from './utils';

const API_URL_SUB = `${process.env.REACT_APP_API_BASE_URL}/payment/create-subscription`;
const API_URL_ON_SUB = `${process.env.REACT_APP_API_BASE_URL}/payment/on-subscribed`;
const API_URL_UPDATE_SUB = `${process.env.REACT_APP_API_BASE_URL}/payment/upgrade-subscription`;
const API_URL_CANCEL_SUB = `${process.env.REACT_APP_API_BASE_URL}/payment/cancel-subscription`;

const subscribe = ({
  userId, billingType, packageName, currency,
}, next, error) => axios.post(API_URL_SUB, {
  userId, billingType, packageName, currency,
}, { headers: authHeader() }).then(({ data }) => {
  next(data);
}).catch((e) => error(e));

const activatePackage = ({
  userId, billingType, packageName,
}, next, error) => axios.post(API_URL_ON_SUB, {
  userId, billingType, packageName,
}, { headers: authHeader() }).then(({ data }) => {
  next(data);
}).catch((e) => error(e));

const updatePackage = ({
  userId, billingType, packageName, currency,
}, next, error) => axios.post(API_URL_UPDATE_SUB, {
  userId, billingType, packageName, currency,
}, { headers: authHeader() }).then(() => {
  next();
}).catch((e) => error(e));

const cancelPackage = ({
  userId,
}, next, error) => axios.post(API_URL_CANCEL_SUB, {
  userId,
}, { headers: authHeader() }).then(({ data }) => {
  next(data);
}).catch((e) => error(e));

export {
  subscribe,
  activatePackage,
  updatePackage,
  cancelPackage,
};
