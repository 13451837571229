import React from 'react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import ChartWrapper from './ChartWrapper';
import LoadingPage from '../LoadingPage';
import Summary from './SummarySection';
import chartsLayout from './chartsLayout';
import dataProcessor from './dataProcessor';
import theme from '../../../theme';

const exampleTagStyle = {
  maxWidth: 'fit-content',
  padding: '0 8px',
  marginLeft: 8,
  borderRadius: 8,
  color: theme.colors.main,
  border: `solid 1px ${theme.colors.goldUpgrade}`,
  fontSize: 13,
};

function ExampleTag() {
  return (
    <div style={exampleTagStyle}>
      example data
    </div>
  );
}

function FormCharts({ formSessions, isFreePackage, dateRange }) {
  const hasSessions = !!formSessions?.length;
  if (!hasSessions && !isFreePackage) return <LoadingPage />;
  return (
    <>
      <div className="mobile-signpost mb-15 d-flex flex-row">
        <div className="mobile-signpost font-rubik text-uppercase">
          Insight Snapshots
        </div>
        {isFreePackage && <ExampleTag />}
      </div>
      <Summary
        charts={chartsLayout}
        hasSessions={hasSessions}
        sessions={formSessions}
        dateRange={dateRange}
        isFreePackage={isFreePackage}
      />
      <div className="mobile-signpost mb-15 d-flex flex-row">
        <div className="mobile-signpost font-rubik text-uppercase">
          Insight details
        </div>
        {isFreePackage && <ExampleTag />}
      </div>

      <Tabs className="portfolio-container d-flex flex-column overflow-auto h-100">
        <div className="controls po-control-one tab-alt mb-30" style={{ background: theme.colors.background }}>
          <TabList className="d-flex flex-wrap justify-content-start">
            {chartsLayout.map(({ label, id }, i) => (
              <Tab key={id}>
                <button type="button" className="control">
                  {label}
                </button>
              </Tab>
            ))}
          </TabList>
        </div>

        <div className="accordion-style-four">
          <div className="accordion-list-wrapper">
            {chartsLayout.map(({
              Chart,
              exampleData, id,
              height, axisLabel, unit, fill, dataName,
            }, i) => {
              const {
                processedData,
                processedAnswer,
              } = hasSessions && dataProcessor(id)(formSessions, dateRange);
              const data = isFreePackage ? exampleData : processedData;
              const chartAnswer = isFreePackage ? [{ value: 'example answer' }] : processedAnswer;
              return (
                <TabPanel key={id}>
                  <div className="card">
                    <div className="card-body">
                      <ChartWrapper chartHeight={height} answer={chartAnswer}>
                        <Chart
                          data={data}
                          axisLabel={axisLabel}
                          unit={unit}
                          fill={fill}
                          dataName={dataName}
                        />
                      </ChartWrapper>
                    </div>
                  </div>
                </TabPanel>
              );
            })}
          </div>
        </div>
      </Tabs>
    </>
  );
}

export default FormCharts;
