import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function DashboardModal({ modalIsOpen, closeModal, children }) {
  return (
    <Modal
      isOpen={!!modalIsOpen}
      onRequestClose={() => closeModal(null)}
      contentLabel="Edit Website"
      className={`custom-modal  modal-contact-popup-one ${modalIsOpen}`}
      overlayClassName="custom-overlay"
      closeTimeoutMS={500}
    >
      {children}
    </Modal>
  );
}

export default DashboardModal;
