import React, { useState, useRef, useEffect } from 'react';
import { Menu, ButtonBase, Button } from '@mui/material';
import moment from 'moment';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import theme from '../../theme';

const buttonContentStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '4px 10px',
  border: `1px solid ${theme.colors.main}`,
  borderRadius: '4px',
  color: theme.colors.textColor,
  width: '100%',
  whiteSpace: 'nowrap',
  justifyContent: 'space-between',
};

const calendarHeaderStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 8,
  padding: '10px 19px 15px',
  marginBottom: '5px',
  borderBottom: `1px solid ${theme.colors.textColor}`,
};

const displayName = (range) => `${moment(range.startDate).format('MMM Do YY')} - ${moment(range.endDate).format('MMM Do YY')}`;

function DateFilter({ handleChange, range }) {
  const [isOpen, setIsOpen] = useState(false);
  const menuAnchorElementRef = useRef(null);

  const [{ startDate, endDate }, setRange] = useState(range);

  const [enteredEnd, setEnteredEnd] = useState(endDate);
  const selectedDays = [startDate, { from: startDate, to: enteredEnd }];

  const maxDate = moment().endOf('day').toDate();
  const minDate = moment().subtract(200, 'days').toDate();
  const disabledDays = { before: minDate, after: maxDate };

  useEffect(() => {
    setRange(range);
    setEnteredEnd(range.endDate);
  }, [range, setRange]);

  const isSelectingFirstDay = (day) => {
    const isBehind = moment(day).isBefore(startDate);
    const isRangeSelected = !!startDate && !!endDate;
    return isBehind || !startDate || isRangeSelected;
  };

  const handleDayClick = (day, { disabled }) => {
    if (disabled) return;

    let newRange;
    let newEnteredEnd;

    if (isSelectingFirstDay(day)) {
      newEnteredEnd = null;
      newRange = { startDate: day, endDate: null };
    } else {
      newEnteredEnd = day;
      newRange = { startDate, endDate: day };
    }
    setEnteredEnd(newEnteredEnd);
    setRange(newRange);

    if (newRange.startDate && newRange.endDate) {
      handleChange({
        startDate: moment(newRange.startDate).startOf('day').toDate(),
        endDate: moment(newRange.endDate).endOf('day').toDate(),
      });
      setIsOpen(false);
    }
  };

  const handleShortcutClick = (numOfDays) => {
    handleChange({
      startDate: moment().subtract(numOfDays, 'days').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
    });
    setIsOpen(false);
  };

  return (
    <div>
      <ButtonBase
        ref={menuAnchorElementRef}
        type="button"
        onClick={() => setIsOpen(true)}
        focusRipple
      >
        <div style={buttonContentStyle}>
          {displayName(range)}
        </div>
      </ButtonBase>
      {menuAnchorElementRef.current && (
      <Menu
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorEl={() => menuAnchorElementRef.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            minWidth: menuAnchorElementRef.current.offsetWidth,
          },
        }}
      >
        <div style={{ minWidth: '500px' }}>
          <div style={calendarHeaderStyle}>
            {
              [30, 90, 120].map((days) => (
                <Button
                  key={days}
                  onClick={() => handleShortcutClick(days)}
                  variant="outlined"
                >
                  {`Last ${days} Days`}
                </Button>
              ))
            }
          </div>
          <DayPicker
            style={{ color: theme.colors.main }}
            mode="range"
            numberOfMonths={2}
            selected={selectedDays}
            disabled={disabledDays}
            onDayClick={handleDayClick}
          />
        </div>
      </Menu>
      )}
    </div>
  );
}

export default DateFilter;
