import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as R from 'ramda';

import { editDomainPages, deleteDomain } from '../../services/domain';
import { refresh } from '../../services/auth';

const trackedPagesEditWrapperStyle = {
  minHeight: 10,
  width: '80%',
  gap: 24,
};

const notify = (message) => toast.error(message || 'Error', {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

function DomainEditor({
  selectedDomain,
  closeModal,
  userId,
}) {
  const [domainState, setDomainState] = useState(JSON.parse(JSON.stringify(selectedDomain)));
  const [clickCount, setClickCount] = useState(0);

  const trackedPages = domainState?.trackedPages;
  const domainName = domainState?.domainName;

  const hasMadeChanges = !R.equals(
    trackedPages,
    selectedDomain?.trackedPages,
  );

  const onDeleteFormPage = (
    formIndex,
  ) => {
    const domainInEdit = { ...domainState };
    domainInEdit?.trackedPages?.splice(formIndex, 1);
    setDomainState(domainInEdit);
  };

  const onChangeForm = (formPageTitle, formIndex) => {
    const domainInEdit = { ...domainState };
    domainInEdit?.trackedPages?.splice(formIndex, 1, formPageTitle);
    setDomainState(domainInEdit);
  };

  const saveChanges = () => {
    if (hasMadeChanges) {
      const { trackedPages: pages, domainKey } = domainState;
      const processedTrackedPages = R.filter((page) => /\S/.test(page))(pages);
      editDomainPages(
        { userId, domainKey, trackedPages: processedTrackedPages },
        refresh,
        (e) => notify(e.message),
      );
    }
  };

  const deleteSelectedDomain = () => {
    setClickCount(clickCount + 1);
    if (clickCount >= 1) {
      const { domainKey } = domainState;
      deleteDomain(
        { userId, domainKey },
        refresh,
        (e) => notify(e.message),
      );
    }
  };

  return (
    <div className="form-style-light mb-0" style={{ borderRadius: 20, padding: 0 }}>
      <div className="mixitUp-container d-flex flex-wrap">
        <div className="portfolio-block-two portfolio-plain position-relative" style={{ minHeight: 350, width: '100%' }}>
          <button
            type="button"
            className="fancybox fancybox-edit fancybox-edit-right"
            onClick={() => closeModal(null)}
          >
            <i className="fa fa-times" aria-hidden="true" />
          </button>

          {
            hasMadeChanges
             && (
             <button
               type="button"
               className="fancybox fancybox-edit fancybox-edit-right-2"
               onClick={saveChanges}
             >
               <i className="fa fa-floppy-o" aria-hidden="true" />
             </button>
             )
          }

          <button
            title="Delete site"
            type="button"
            className="fancybox fancybox-edit fancybox-edit-left"
            onClick={deleteSelectedDomain}
          >
            {clickCount < 1
              ? <i className="fa fa-trash" aria-hidden="true" />
              : <span style={{ fontSize: 11 }}>Sure?</span>}
          </button>

          <div
            className="d-flex align-items-center flex-column pt-30 pb-30 position-relative"
            style={{ height: '100%', gap: 24 }}
          >
            <p className="mobile-form-title font-rubik text-uppercase">{domainName}</p>
            <div className="d-flex align-items-center flex-column pt-20 pb-40" style={{ gap: 32, width: '100%' }}>
              {
                trackedPages?.map((page, index) => (
                  <div
                    key={page}
                    className="d-flex justify-content-between align-items-center flex-row"
                    style={trackedPagesEditWrapperStyle}
                  >
                    <div className="input-group-meta" style={{ height: '100%' }}>
                      <input
                        type="text"
                        value={page}
                        onChange={(e) => onChangeForm(e.target.value, index)}
                        style={{ fontSize: 18, textOverflow: 'ellipsis' }}
                        placeholder="e.g. my-form-path"
                      />
                    </div>
                    <button type="button" onClick={() => onDeleteFormPage(index)}>
                      <i className="fa fa-trash" aria-hidden="true" />
                    </button>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default DomainEditor;
