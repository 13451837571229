import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { activatePackage } from '../../services/payment';
import { updateAuthenticatedUserPackage } from '../../services/user';

const buttonWrapperStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

const imageStyle = {
  height: 200,
  width: 200,
};

const content = {
  success: {
    introText: 'Success',
    headertext: 'Thank you for upgrading your package!',
    subText: 'An email has been sent to confirm your upgrade.',
  },
  failed: {
    introText: '',
    headertext: 'Something went wrong',
    subText: 'Please get in touch with our team for assistance.',
  },
};

function UpgradeConfirmation() {
  const [status, setStatus] = useState('');
  const {
    userId, packageName, billingType, billingId,
  } = useParams();

  useEffect(() => {
    activatePackage(
      {
        userId, billingType, packageName, billingId,
      },
      (user) => {
        setStatus('success');
        updateAuthenticatedUserPackage(user);
      },
      () => {
        setStatus('failed');
      },
    );
  }, [status]);

  return (
    <>
      <Helmet>
        <title>
          Package Upgrade - Insiteform
        </title>
      </Helmet>
      <div className="full-height-layout d-flex align-items-center">
        <div className="coming-soon-content font-rubik">
          <div className="logo coming-soon-brand">
            <Link to="/dashboard">
              <img src="/images/logo/insiteform-full.svg" alt="brand logo" style={imageStyle} />
            </Link>
          </div>
          <h6>{content[status]?.introText}</h6>
          <h1 data-aos="fade-up">{content[status]?.headertext}</h1>
          <div className="row">
            <div className="col-lg-9 m-auto">
              <p>
                {content[status]?.subText}
              </p>
            </div>
          </div>

          <Link to="/dashboard" style={buttonWrapperStyle}>
            <button type="button" className="theme-btn-one">Go to Dashboard</button>
          </Link>

          <img
            src="/images/shape/179.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="/images/shape/180.svg"
            alt="shape"
            className="shapes shape-two"
          />
          <img
            src="/images/shape/181.svg"
            alt="shape"
            className="shapes shape-three"
          />
          <img
            src="/images/shape/182.svg"
            alt="shape"
            className="shapes shape-four"
          />
          <img
            src="/images/shape/183.svg"
            alt="shape"
            className="shapes shape-five"
          />
          <img
            src="/images/shape/184.svg"
            alt="shape"
            className="shapes shape-six"
          />
          <img
            src="/images/shape/185.svg"
            alt="shape"
            className="shapes shape-seven"
          />
          <img
            src="/images/shape/186.svg"
            alt="shape"
            className="shapes shape-eight"
          />
          <img
            src="/images/shape/187.svg"
            alt="shape"
            className="shapes shape-nine"
          />
          <img
            src="/images/shape/188.svg"
            alt="shape"
            className="shapes shape-ten"
          />
        </div>
      </div>
    </>
  );
}

export default UpgradeConfirmation;
