import React from 'react';
import { Link } from 'react-router-dom';

const BlogContent = [
  {
    img: 'images/assets/ils_09.svg',
    tag: '',
    title: 'Form tracking 101',
    routePath: '/why-form-tracking',
    dealyAnimation: '',
  },
  {
    img: 'images/assets/ils_09.svg',
    tag: '',
    title: 'What is Insiteform?',
    routePath: '/what-is-insiteform',
    dealyAnimation: '100',
  },
];

function BlogLanding() {
  return (
    <>
      {BlogContent.map((item, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          key={i}
          data-aos-delay={item.dealyAnimation}
        >
          <div className="post-meta">
            <img
              src={item.img}
              alt="media"
              className="image-meta"
            />
            <div className="tag">{item.tag}</div>
            <h3>
              <Link to={item.routePath} className="title">
                {item.title}
              </Link>
            </h3>
            <Link
              to={item.routePath}
              className="read-more d-flex justify-content-between align-items-center"
            >
              <span>Read More</span>
              <i className="flaticon-right-arrow" />
            </Link>
          </div>
        </div>
      ))}
    </>
  );
}

export default BlogLanding;
