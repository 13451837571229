import React, { useState } from 'react';
import Session from './Session';

export default function PaginatedSessions({
  sortedSessions, pageLimit,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const pages = Math.ceil(sortedSessions.length / pageLimit);

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * pageLimit - pageLimit;
    const endIndex = startIndex + pageLimit;
    return sortedSessions.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    const start = Math.floor((currentPage - 1) / pages) * pages;
    return new Array(pages).fill().map((_, idx) => start + idx + 1);
  };

  return (
    <>
      <div style={pages > 1 ? { minHeight: 350 } : {}}>
        {getPaginatedData().map((session) => (
          <Session session={session} key={session.sessionId} />
        ))}
      </div>
      {
        pages > 1 && (
        <div className="session-pagination">
          <button
            type="button"
            onClick={goToPreviousPage}
            className={`theme-btn-two prev ${currentPage === 1 ? 'disabled' : ''}`}
          >
            Prev
          </button>
          {getPaginationGroup().map((item, index) => (
            <button
              type="button"
              key={index}
              onClick={changePage}
              className={`paginationItem ${currentPage === item ? 'active' : null}`}
            >
              <span>{item}</span>
            </button>
          ))}
          <button
            type="button"
            onClick={goToNextPage}
            className={`theme-btn-two next ${currentPage === pages ? 'disabled' : ''}`}
          >
            Next
          </button>
        </div>
        )
      }
    </>
  );
}
