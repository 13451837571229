/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CopyRight from '../components/footer/CopyRight';
import WhatInsiteform from '../components/blog/WhatInsiteform';
import WhyFormTracking from '../components/blog/WhyFormTracking';

function BlogDetails() {
  const { pathname } = useLocation();
  const Article = {
    '/what-is-insiteform': <WhatInsiteform />,
    '/why-form-tracking': <WhyFormTracking />,
  };
  const meta = {
    '/what-is-insiteform': {
      title: 'Unlock the Power of Form Tracking with Insiteform',
      description: "Optimize your website's performance and gain valuable insights with Insiteform. Features include A/B testing and in-depth analytics. Click through to learn more.",
    },
    '/why-form-tracking': {
      title: 'Tracking and Analysing Visitor Activity on Your Form: The Top 5 Benefits',
      description:
      'Learn about the top five benefits of tracking and analysing visitor activity on your customer signup form, including increased conversions, improved customer experience, and more. Find out how form tracking can help your business succeed.',
    },
  };
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>{meta[pathname].title}</title>
        <meta name="description" content={meta[pathname].description} />
        <meta
          name="keywords"
          content="hubspot forms, wordpress forms, webflow forms, form analytics, form tracking, form optimisation, form performance, form conversion rate, form data analysis, form completion rate, form user experience, form optimisation tools"
        />
        <meta
          name="description"
          content="Insiteform is a powerful tool for tracking and improving the performance of your forms. With its analytics and tracking features, you can increase your form's conversion rate and boost conversions."
        />
      </Helmet>
      <div className="blog-page-bg">
        <div className="shapes shape-one" />
        <div className="shapes shape-two" />
        <div className="shapes shape-three" />
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-lg-12 feature-blog-one width-lg blog-details-post-v1">
              {Article[pathname] ? Article[pathname] : null}
              {/* /End post content  */}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center mt-20">
          <Link to="sign-up" className="get-started">
            <span>Get Started With Insiteform For Free</span>
          </Link>
        </div>
      </div>

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <div className="col-lg-3 col-md-4 address-list d-flex align-items-center mb-20">
              <CopyRight />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default BlogDetails;
