/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/user-message`;

const sendMessage = ({
  userId,
  userName,
  userEmail,
  message,
}, next, error) => axios.post(`${API_URL}`, {
  userId, userName, userEmail, message,
}).then(() => {
  next();
}).catch((e) => error(e));

export {
  sendMessage,
};
