import React from 'react';
import * as R from 'ramda';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import FormCharts from './FormCharts';
import theme from '../../../theme';

const groupFormWithSessions = (forms, sessions) => R.map((i) => ({
  formName: i,
  formSessions: R.filter(R.propEq('formPage', i), sessions),
}), forms);

const truncateTitle = (str) => {
  if (str.length > 10) return `${str.substring(0, 10)}...`;
  return str;
};

function DomainFormsAnalytics({
  forms, formSessions, isFreePackage, dateRange,
}) {
  if (!forms || !formSessions) return null;
  const groupedFormSessions = groupFormWithSessions(forms, formSessions);
  return (
    <Tabs className="portfolio-container d-flex flex-column overflow-auto h-100">
      <div className="controls po-control-one sticky-top" style={{ background: theme.colors.background }}>
        <TabList className="d-flex flex-wrap justify-content-start">
          {R.pluck('formName', groupedFormSessions).map((form, i) => (
            <Tab key={i}>
              <button type="button" className="control">
                {truncateTitle(form)}
              </button>
            </Tab>
          ))}
        </TabList>
      </div>

      {R.pluck('formSessions', groupedFormSessions).map((sessions, i) => (
        <TabPanel key={i}>
          <FormCharts
            formSessions={sessions}
            dateRange={dateRange}
            isFreePackage={isFreePackage}
          />
        </TabPanel>
      ))}
    </Tabs>
  );
}

export default DomainFormsAnalytics;
