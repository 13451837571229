const colors = {
  blue: '#3a6dee',
  textColor: '#73737b',
  textLight: '#888888',
  main: '#0295A9',
  background: '#f7faff',
  blueLight: '#22CBDD',
  redLight: '#fd6a5e',
  blueDark: '#3422DD',
  sidebarBackground: '#17192bf9',
  grayedOut: '#313846',
  red: '#eb5463',
  yellow: '#fdcd56',
  green: '#45ceac',
  goldUpgrade: '#e2c634',
  purpleLight: '#8884d8',
  greenLight: '#82ca9d',
};

const theme = {
  colors,
};

export default theme;
