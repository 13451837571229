import React from 'react';
import {
  Box, InputLabel, MenuItem, FormControl, Select,
} from '@mui/material';
import theme from '../../theme';

export default function SessionFilter({ filterStatus, handleChangeFilter }) {
  return (
    <Box sx={{ minWidth: 100 }}>
      <FormControl fullWidth>
        <InputLabel id="session-filter-label">Show</InputLabel>
        <Select
          labelId="session-filter-label"
          id="session-filter"
          value={filterStatus}
          label="Status"
          onChange={handleChangeFilter}
          sx={{ color: theme.colors.main }}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
          <MenuItem value="abandoned">Abandoned</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
