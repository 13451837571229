import React from 'react';
import { Helmet } from 'react-helmet';
import BlogLanding from '../components/blog/BlogLanding';
import CopyRight from '../components/footer/CopyRight';

function Blog() {
  return (
    <div className="main-page-wrapper pt-0">
      <Helmet>
        <title>Blog - Insiteform</title>
      </Helmet>
      {/* End Page SEO Content */}
      {/* 	=============================================
            Fancy Hero One
        ==============================================  */}
      <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-10 m-auto">
              <h2 className="font-rubik">
                Maximize your website&apos;s potential
              </h2>
            </div>
          </div>
        </div>
        <div className="bubble-one" />
        <div className="bubble-two" />
        <div className="bubble-three" />
        <div className="bubble-four" />
        <div className="bubble-five" />
        <div className="bubble-six" />
      </div>
      {/* /.fancy-hero-one */}

      {/* =====================================================
            Feature Blog One
        ===================================================== */}
      <div className="feature-blog-one blog-page-bg">
        <div className="shapes shape-one" />
        <div className="shapes shape-two" />
        <div className="shapes shape-three" />
        <div className="container">
          <div className="row">
            <BlogLanding />
          </div>
          {/* End .row */}
        </div>
      </div>
      {/* /.feature-blog-one */}

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
}

export default Blog;
