import axios from 'axios';
import * as R from 'ramda';
import authHeader from './utils';
import { STORAGE_KEY } from './auth';

const API_URL_PATCH = `${process.env.REACT_APP_API_BASE_URL}/user/update-user`;
const API_URL_PWD_PUT = `${process.env.REACT_APP_API_BASE_URL}/user/update-pwd`;

const updateUserDetails = ({ userId, name, email }, next, error) => axios.patch(
  `${API_URL_PATCH}/${userId}`,
  {
    name,
    email,
  },
  { headers: authHeader() },
).then(({ data }) => {
  next(data);
}).catch((e) => error(e));

const updateUserPwd = ({ userId, currentPassword, newPassword }, next, error) => axios.patch(
  `${API_URL_PWD_PUT}/${userId}`,
  { currentPassword, newPassword },
  { headers: authHeader() },
).then(() => {
  next();
}).catch((e) => error(e));

const deleteUser = ({ userId }, next, error) => axios.patch(
  `${API_URL_PATCH}/${userId}`,
  { isDeleted: true },
  { headers: authHeader() },
).then(() => {
  next();
}).catch((e) => error(e));

const updateAuthenticatedUserDetails = (userUpdates) => {
  const currentUser = JSON.parse(localStorage.getItem(STORAGE_KEY));
  const nameUpdated = R.assocPath(['authenticatedUser', 'name'], userUpdates.name, currentUser);
  const emailUpdated = R.assocPath(['authenticatedUser', 'email'], userUpdates.email, nameUpdated);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(emailUpdated));
};

const updateAuthenticatedUserPackage = (userUpdates) => {
  const currentUser = JSON.parse(localStorage.getItem(STORAGE_KEY));
  const packageUpdated = R.assocPath(['authenticatedUser', 'packageName'], userUpdates.packageName, currentUser);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(packageUpdated));
};

const updateAuthenticatedUserPackageStatus = (userUpdates) => {
  const currentUser = JSON.parse(localStorage.getItem(STORAGE_KEY));
  const billingUpdated = R.assocPath(['authenticatedUser', 'billingEnd'], userUpdates.billingEnd, currentUser);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(billingUpdated));
};

export {
  updateUserDetails, updateUserPwd, deleteUser,
  updateAuthenticatedUserPackage, updateAuthenticatedUserDetails,
  updateAuthenticatedUserPackageStatus,
};
