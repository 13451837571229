import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function NotFound() {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>Not Found - Insiteform</title>
      </Helmet>
      {/* End Page SEO Content */}

      <div className="error-page d-lg-flex align-items-center">
        <div className="img-holder order-lg-last">
          <img
            src="/images/media/404.svg"
            alt="media"
            className="w-100 illustration"
          />
          <img
            src="/images/media/404-q.svg"
            alt="media"
            className="shapes qus"
          />
        </div>
        <div className="text-wrapper order-lg-first">
          <div className="logo">
            <Link to="/">
              <img src="/images/logo/insiteform.svg" alt="logo" style={{ height: 200, marginLeft: '-40%' }} />
            </Link>
          </div>
          <h1 className="font-rubik">
            Sorry,
            {' '}
            <br />
            This Page Doesn’t
            {' '}
            <br />
            Exist.
          </h1>
        </div>
        {/* /.text-wrapper */}
      </div>
      {/* /.error-page */}
    </div>
  );
}

export default NotFound;
