import {
  SessionAreachart,
  SessionBarchart,
  VerticalBarchart,
  SessionHistogram,
  SessionPiechart,
} from './charts';

import {
  exampleAreachartFills,
  exampleBarchartNumberOfInteractions,
  exampleBarchartAverageTimeSpent,
  exampleBarchartTotalTimeSpent,
  examplePiechartDevice,
  exampleHistogramDeviceCompletion,
  exampleBarchartLoaction,
  examplePiechartCompletion,
  exampleHistogramVisitorLocation,
} from './exampleData';

import theme from '../../../theme';

const layoutData = [
  {
    id: 'funnel',
    label: 'Form fills',
    Chart: SessionAreachart,
    height: 500,
    exampleData: exampleAreachartFills,
    axisLabel: 'Fill volume',
    unit: '',
    fill: theme.colors.main,
    dataName: 'No. of times filled',
  },

  {
    id: 'number-of-sessions',
    label: 'Form starts',
    Chart: SessionBarchart,
    height: 500,
    exampleData: exampleBarchartNumberOfInteractions,
    axisLabel: 'Session volume',
    unit: '',
    fill: theme.colors.main,
    dataName: 'No. of starts',
  },

  {
    id: 'completion',
    label: 'Form completions',
    Chart: SessionPiechart,
    height: 400,
    exampleData: examplePiechartCompletion,
    axisLabel: '',
    unit: '',
    fill: '',
    dataName: '',
  },

  {
    id: 'average-session-time',
    label: 'Average times',
    Chart: SessionBarchart,
    height: 500,
    exampleData: exampleBarchartAverageTimeSpent,
    axisLabel: 'Daily average (s)',
    unit: ' seconds',
    fill: theme.colors.purpleLight,
    dataName: 'Average session time',
  },

  {
    id: 'total-session-time',
    label: 'Total times',
    Chart: SessionBarchart,
    height: 500,
    exampleData: exampleBarchartTotalTimeSpent,
    axisLabel: 'Daily total (m)',
    unit: ' minutes',
    fill: theme.colors.greenLight,
    dataName: 'Total session time',
  },

  {
    id: 'device',
    label: 'Devices',
    Chart: SessionPiechart,
    height: 400,
    exampleData: examplePiechartDevice,
    axisLabel: '',
    unit: '',
    fill: '',
    dataName: '',
  },

  {
    id: 'outcome-by-device',
    label: 'Outcomes by device',
    Chart: SessionHistogram,
    height: 500,
    exampleData: exampleHistogramDeviceCompletion,
    axisLabel: 'Session volume',
    unit: '',
    fill: '',
    dataName: '',
  },

  {
    id: 'location',
    label: 'Locations',
    Chart: VerticalBarchart,
    height: 500,
    exampleData: exampleBarchartLoaction,
    axisLabel: 'Session volume',
    unit: '',
    fill: theme.colors.main,
    dataName: 'No. of sessions',
  },

  {
    id: 'outcome-by-location',
    label: 'Outcomes by location',
    Chart: SessionHistogram,
    height: 500,
    exampleData: exampleHistogramVisitorLocation,
    axisLabel: 'Session volume',
    unit: '',
    fill: '',
    dataName: 'No. of sessions',
  },
];

export default layoutData;
