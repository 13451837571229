import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Surface,
  ResponsiveContainer,
} from 'recharts';
import { capitalize } from '@mui/material';
import theme from '../../../../theme';

const getDataKeys = (exampleData) => {
  const dataKeys = Object.entries(exampleData[0]);
  const yAxisKeys = dataKeys.map(([key, value]) => (
    typeof value === 'number' ? key : null)).filter(Boolean);
  const xAxisKey = dataKeys.map(([key, value]) => (
    typeof value !== 'number' ? key : null)).filter(Boolean);
  return [xAxisKey[0], yAxisKeys];
};

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  margin: '15px 0 0',
};

const labelStyle = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

const labelWrapperStyle = {
  display: 'flex',
  gap: 4,
  flexDirection: 'row',
  alignItems: 'baseline',
};

function Dot({ fill, size }) {
  const viewBox = {
    x: 0, y: 0, width: size * 2, height: size * 2,
  };
  const svgStyle = {
    display: 'inline',
    marginRight: 0,
    marginTop: Math.round(size / 2),
  };
  return (
    <Surface width={size} height={size} viewBox={viewBox} style={svgStyle}>
      <circle stroke="none" fill={fill} cx={size} cy={size} r={size} />
    </Surface>
  );
}

function CustomLegend({ payload }) {
  return (
    <div style={containerStyle}>
      <div className="d-flex" style={{ gap: 32 }}>
        {payload.map((slice) => (
          <div key={`legend-item-${slice.payload.name}`} style={labelWrapperStyle}>
            <Dot fill={slice.payload.fill} size={9} />
            <div style={labelStyle} title={slice.payload.name}>
              {capitalize(slice.payload.name)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default function SessionHistogram({
  data, axisLabel,
}) {
  const [xAxisKey, yAxisKeys] = getDataKeys(data);
  const keyColours = {
    abandoned: theme.colors.red,
    completed: theme.colors.green,
  };
  return (
    <ResponsiveContainer width="99%">
      <BarChart
        data={data}
        margin={{
          top: 30,
          bottom: 20,
        }}
      >
        <CartesianGrid key="grid" vertical={false} />
        <XAxis dataKey={xAxisKey} type="category" style={{ fontSize: 15 }} />
        <YAxis
          allowDecimals={false}
          style={{ fontSize: 15 }}
          type="number"
          label={{
            value: axisLabel.toUpperCase(),
            position: 'top',
            offset: 8,
            fontSize: 11,
            dy: -10,
            dx: 20,
            fill: theme.colors.textLight,
          }}
        />
        <Tooltip />
        <Legend content={CustomLegend} />
        {
          yAxisKeys.map((key, idx) => <Bar key={`key-${idx}`} dataKey={key} stackId="a" fill={keyColours[key]} name={key} />)
        }
      </BarChart>
    </ResponsiveContainer>
  );
}
