/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import theme from '../../theme';

const script = (domainKey) => `<script>
  let insiteformscript = document.createElement("script");
  let loc = window.location.href;
  insiteformscript.setAttribute("src", "https://api.insiteform.com/v1/insiteform-script?loc=" + loc + "&dk=${domainKey}");
  document.body.appendChild(insiteformscript);
</script>`;

const domainKeyStyle = {
  cursor: 'pointer',
  background: theme.colors.blueLight,
  width: '100%',
  textAlign: 'center',
  userSelect: 'text',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const copyKey = (domainKey) => navigator.clipboard.writeText(domainKey);

function SavedDomains({ domains, isWPSetup }) {
  const [isCopied, setIsCopied] = useState(null);
  return domains.map(({ domainName, domainKey }, index) => {
    const onCopyKey = () => {
      copyKey(domainKey);
      setIsCopied(index);
      setTimeout(() => setIsCopied(null), 2000);
    };
    return (
      <div
        key={`${domainKey}${index}`}
        className="mix"
        data-aos="fade-right"
        data-aos-delay="200"
        style={{ flex: 1, maxWidth: 500, width: '100%' }}
      >
        <div className="portfolio-block-two position-relative">
          <button
            title="Copy"
            type="button"
            className="fancybox fancybox-edit fancybox-edit-right"
            onClick={onCopyKey}
          >
            <i className="fa fa-clone" aria-hidden="true" />
          </button>
          <div
            className="d-flex align-items-center flex-column pt-30 pb-30 position-relative"
            style={{ height: '100%', gap: 24 }}
          >
            <p className="mobile-form-title font-rubik text-uppercase">{domainName}</p>
            {isCopied === index && <span style={{ position: 'absolute', bottom: 0 }}>copied</span>}
            {
              isWPSetup
                ? (
                  <div style={domainKeyStyle} onClick={onCopyKey}>
                    {domainKey}
                  </div>
                ) : (
                  <div style={{ padding: 8, cursor: 'pointer' }} onClick={onCopyKey}>
                    <code>
                      {script(domainKey)}
                    </code>
                  </div>
                )
            }
          </div>
        </div>
      </div>
    );
  });
}

export default SavedDomains;
