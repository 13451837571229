import React from 'react';
import ContactForm from '../forms/ContactForm';

const contentStyle = {
  minHeight: 350,
  width: '100%',
};

function Contact({
  closeModal,
  userName,
  userEmail,
  userId,
}) {
  const close = () => closeModal(null);
  return (
    <div
      className="form-style-light mb-0"
      style={{ borderRadius: 20, padding: 0 }}
    >
      <div className="mixitUp-container d-flex flex-wrap">
        <div
          className="portfolio-block-two portfolio-plain d-flex flex-row justify-content-center position-relative"
          style={contentStyle}
        >
          <button
            type="button"
            className="fancybox fancybox-edit fancybox-edit-right"
            onClick={close}
          >
            <i className="fa fa-times" aria-hidden="true" />
          </button>
          <div className="d-flex align-items-center flex-column pt-80 pb-30 position-relative h-100 w-75">
            <ContactForm
              closeModal={close}
              userName={userName}
              userEmail={userEmail}
              userId={userId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
