import React from 'react';

function CopyRight() {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <p>
        Copyright @
        {new Date().getFullYear()}
        {' '}
        Insiteform
      </p>
    </div>
  );
}

export default CopyRight;
