/* eslint-disable max-len */
import React from 'react';
import * as R from 'ramda';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import FormSessions from './FormSessions';
import theme from '../../../theme';
import { isCompleted } from '../analytics/utils';

const truncateTitle = (str) => {
  if (str.length > 10) return `${str.substring(0, 10)}...`;
  return str;
};

const groupFormWithSessions = (forms, sessions) => R.map((i) => ({
  formName: i,
  formSessions: R.filter(R.propEq('formPage', i), sessions),
}), forms);

const filterSessions = ({ filterStatus, sessions }) => sessions.map((sessionsArray) => sessionsArray.filter((i) => {
  if (filterStatus === 'completed') {
    return isCompleted(i.formData) && i;
  } if (filterStatus === 'abandoned') {
    return !isCompleted(i.formData) && i;
  }
  return i;
}));

function DomainFormsSessions({ forms, formSessions, filterStatus }) {
  if (!forms || !formSessions) return null;
  const groupedFormSessions = groupFormWithSessions(forms, formSessions);
  const filteredSessions = filterSessions({ filterStatus, sessions: R.pluck('formSessions', groupedFormSessions) });
  return (
    <Tabs className="portfolio-container d-flex flex-column overflow-auto h-100">
      <div className="controls po-control-one  sticky-top" style={{ background: theme.colors.background }}>
        <TabList className="d-flex flex-wrap justify-content-start">
          {R.pluck('formName', groupedFormSessions).map((form, i) => (
            <Tab key={i}>
              <button type="button" className="control">
                {truncateTitle(form)}
              </button>
            </Tab>
          ))}
        </TabList>
      </div>

      {filteredSessions.map((sessions, i) => (
        <TabPanel key={i}>
          <FormSessions formSessions={sessions} />
        </TabPanel>
      ))}
    </Tabs>
  );
}

export default DomainFormsSessions;
