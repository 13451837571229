import React from 'react';

function PriceSummary({
  className, frequency, currency, pricePerPeriod, isUpgrade,
}) {
  return (
    <div className={`${className} ledger`}>
      {/* Display the price per period */}
      <p className="ledger-entry">
        <span className="ledger-label">
          {frequency}
          :
        </span>
        <span className="ledger-value">
          {currency}
          {pricePerPeriod}
        </span>
      </p>
      { !isUpgrade && (
      <p className="ledger-entry display-tab">
        <span className="ledger-label">
          To pay now:
        </span>
        <span className="ledger-value">
          {currency}
          {pricePerPeriod}
        </span>
      </p>
      )}
    </div>
  );
}

export default PriceSummary;
