import React from 'react';
import {
  BarChart,
  Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import theme from '../../../../theme';

const getDataKeys = (exampleData) => {
  const dataKeys = Object.entries(exampleData[0]);
  const yAxisKey = dataKeys.map(([key, value]) => (
    typeof value === 'number' ? key : null)).filter(Boolean);
  const xAxisKey = dataKeys.map(([key, value]) => (
    typeof value !== 'number' ? key : null)).filter(Boolean);
  return [xAxisKey[0], yAxisKey[0]];
};

export default function SessionBarchart({
  data, axisLabel, unit, fill, dataName,
}) {
  const [xAxisKey, yAxisKey] = getDataKeys(data);
  return (
    <ResponsiveContainer width="99%">
      <BarChart
        data={data}
        margin={{
          top: 30,
          bottom: 20,
        }}
      >
        <CartesianGrid key="grid" vertical={false} />
        <XAxis dataKey={xAxisKey} type="category" style={{ fontSize: 15 }} />
        <YAxis
          allowDecimals={false}
          style={{ fontSize: 15 }}
          type="number"
          label={{
            value: axisLabel.toUpperCase(),
            position: 'top',
            offset: 8,
            fontSize: 11,
            dy: -10,
            dx: 20,
            fill: theme.colors.textLight,
          }}
        />
        <Tooltip />
        <Bar
          dataKey={yAxisKey}
          fill={fill}
          name={dataName}
          unit={unit}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
