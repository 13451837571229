import React from 'react';
import {
  Table, TableBody, TableCell, TableRow, TableContainer, TableHead,
} from '@mui/material';
import theme from '../../../theme';

const getColor = ({ value, visitorInteraction }) => {
  if (value) {
    return theme.colors.textColor;
  } if (visitorInteraction) {
    return theme.colors.green;
  }
  return theme.colors.red;
};

const getInteractionLabel = ({ visitorInteraction, fieldType, value }) => {
  if (fieldType === 'button' || fieldType === 'submit') return visitorInteraction ? 'clicked' : 'not clicked';
  if (value) return `[${value}]`;
  return visitorInteraction ? 'filled' : 'empty';
};

export default function FormTable({ userForm }) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Field Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Interaction Order</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userForm?.map(({
            fieldName, fieldId, interactionOrder, visitorInteraction, fieldType, value,
          }, i) => (
            <TableRow
              key={`${fieldId}-${i}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {fieldName}
              </TableCell>
              <TableCell style={{
                color: getColor({ value, visitorInteraction }),
              }}
              >
                {getInteractionLabel({ visitorInteraction, fieldType, value })}
              </TableCell>
              <TableCell>{interactionOrder}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  );
}
