/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setNewPassword } from '../../services/reset';

function SaveReset() {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const [rePasswordShown, setRePasswordShown] = useState(false);
  const toggleRePasswordVisiblity = () => {
    setRePasswordShown(!rePasswordShown);
  };

  const history = useHistory();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const notify = (message) => toast.error(message || 'Error', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const { accessToken } = useParams();

  function onSubmit({ password }) {
    setNewPassword(
      { newPassword: password, accessToken },
      () => {
        history.push('/login');
      },
      (error) => {
        const { message } = error;
        notify(message);
        if (process.env.NODE_ENV === 'development') console.log(error);
      },
    );
  }

  return (
    <form className="user-data-form " onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12">
          <div className="input-group-meta mb-50">
            <label>Password</label>
            <input
              placeholder="Enter Password"
              name="password"
              type={passwordShown ? 'text' : 'password'}
              {...register('password')}
              className={` ${errors.password ? 'is-invalid' : ''}`}
            />
            {errors.password && (
            <div className="invalid-feedback">
              {errors.password?.message}
            </div>
            )}
            {/* End error msg */}
            <span
              className="placeholder_icon"
              onClick={togglePasswordVisiblity}
            >
              <span
                className={
                    passwordShown ? 'passVicon eye-slash' : 'passVicon'
                  }
              >
                <img src="/images/icon/view.svg" alt="" />
              </span>
            </span>
            {/* End placeholder icon */}
          </div>
        </div>
        <div className="col-12">
          <div className="input-group-meta mb-25">
            <label>Re-type Password</label>
            <input
              placeholder="Enter Password"
              name="confirmPassword"
              type={rePasswordShown ? 'text' : 'password'}
              {...register('confirmPassword')}
              className={` ${errors.confirmPassword ? 'is-invalid' : ''}`}
            />
            {errors.confirmPassword && (
            <div className="invalid-feedback">
              {errors.confirmPassword?.message}
            </div>
            )}
            {/* End error msg */}
            <span
              className="placeholder_icon"
              onClick={toggleRePasswordVisiblity}
            >
              <span
                className={
                    rePasswordShown ? 'passVicon eye-slash' : 'passVicon'
                  }
              >
                <img src="/images/icon/view.svg" alt="" />
              </span>
            </span>
            {/* End placeholder icon */}
          </div>
        </div>
        <div className="col-12">
          <button type="submit" className="theme-btn-one mt-30 mb-50">
            Save New Password
          </button>
        </div>
        {/* End .col */}
      </div>
    </form>
  );
}

export default SaveReset;
