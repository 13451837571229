import React, { useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createDomain } from '../../services/domain';
import theme from '../../theme';
import { refresh } from '../../services/auth';

const formContentStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '8px',
  marginTop: 16,
};

const formInputStyle = {
  flex: 1,
  maxWidth: '85%',
  borderRadius: '8px',
  fontSize: '16px',
  border: 'solid 1px #d6d6d6',
  padding: '0 20px',
  color: theme.colors.textColor,
  background: 'transparent',
  fontFamily: 'Rubik',
  textOverflow: 'ellipsis',
};

const isValidUrl = (url) => {
  const pattern = /^(http:\/\/|https:\/\/)(?!www\.)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return pattern.test(url);
};

const convertURL = (url) => url.replace('www.', '');

const notifySuccess = () => toast.success('Form added!', {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

function SetupForm({ userId, canAddForm, track }) {
  const [formError, setFormError] = useState(null);
  const onError = (e) => {
    setFormError(e);
    setTimeout(() => setFormError(null), 3000);
  };
  const history = useHistory();
  function onSubmit(e) {
    e.preventDefault();
    try {
      track({ label: 'Add form' });
      const cleanUrl = convertURL(e.target[0].value);
      const isValid = isValidUrl(cleanUrl);
      if (isValid) {
        const { hostname: domainName, pathname } = new URL(cleanUrl);
        const paths = pathname.split('/');
        const formLocation = paths.filter(Boolean).pop() || 'home-page';

        createDomain(
          { userId, domainName, trackedPages: [formLocation] },
          () => {
            notifySuccess();
            track({ label: 'Form Added' });
            setTimeout(refresh, 2500);
          },
          (error) => {
            const errorMessage = error.response?.data || error.message;
            onError(errorMessage);
          },
        );
      } else {
        onError('Incorrect format');
      }
    } catch (error) {
      onError(error.message);
    }
  }

  return (
    <form id="setup-form" onSubmit={onSubmit}>
      <div style={formContentStyle}>
        <input
          style={formInputStyle}
          placeholder="e.g. https://www.your-website.com/form-page"
          type="url"
        />
        {
          canAddForm ? <button type="submit" className="theme-btn-one">Add</button>
            : (
              <Tooltip title={<Typography fontSize={14}>Upgrade your package to add another form</Typography>} placement="bottom">
                <button
                  type="button"
                  className="theme-btn-one"
                  style={{ background: theme.colors.goldUpgrade }}
                  onClick={() => history.push('/packages')}
                >
                  <i className="fa fa-diamond" aria-hidden="true" />
                </button>
              </Tooltip>
            )
        }
      </div>
      <div className="col-12">
        {formError && (
          <div className="invalid-feedback">
            {formError}
          </div>
        )}
      </div>
    </form>
  );
}

export default SetupForm;
