import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MenuMobile from './MenuMobile';
import { getCurrentUser, logout } from '../../services/auth';
import theme from '../../theme';

function Header({ track }) {
  const [navbar, setNavbar] = useState(false);
  const user = getCurrentUser();
  const userName = user?.authenticatedUser?.name;

  const changeBackground = () => {
    if (window.scrollY >= 68) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changeBackground);

  return (
    <>
      <div
        className={
          navbar
            ? 'theme-main-menu sticky-menu theme-menu-three bg-none fixed'
            : 'theme-main-menu sticky-menu theme-menu-three bg-none'
        }
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo">
            <Link to="/">
              <img src="/images/logo/insiteform-full.svg" alt="brand logo" style={{ height: 100, width: 100 }} />
            </Link>
          </div>
          {/* End Logo */}

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div
                className="navbar-collapse collapse"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center" style={{ height: 90 }}>
                  <ul className="right-widget user-login-button d-flex align-items-center justify-content-center">
                    {
                      userName ? (
                        <>
                          <li>
                            <button
                              type="button"
                              style={{ color: theme.colors.main }}
                              onClick={logout}
                            >
                              <span>
                                Not
                                {' '}
                                {userName}
                                ? - Log out
                              </span>
                            </button>
                          </li>
                          <li>
                            <Link
                              to="/dashboard"
                              className="signUp-action d-flex align-items-center"
                            >
                              <span>
                                Welcome back,
                                {' '}
                                {userName}
                              </span>
                              <img src="/images/icon/53.svg" alt="icon" />
                            </Link>
                          </li>

                        </>

                      ) : (
                        <>
                          <li>
                            <Link
                              to="login"
                              className="signIn-action d-flex align-items-center"
                            >
                              <img src="/images/icon/52.svg" alt="icon" />
                              <span>Login</span>
                            </Link>
                          </li>
                          {/* End li */}
                          <li>
                            <Link
                              to="sign-up"
                              className="signUp-action d-flex align-items-center"
                              onClick={() => track({ label: 'Get Started For Free' })}
                            >
                              <span>Get Started For Free</span>
                              <img src="/images/icon/53.svg" alt="icon" />
                            </Link>
                          </li>
                        </>
                      )
                    }
                  </ul>
                  {/* End right-button-group  */}
                </div>
              </div>
            </div>
          </nav>
          {/* End nav */}
        </div>

        <MenuMobile userName={userName} />
        {/* End Mega Menu for Mobile */}
      </div>
      {/* /.theme-main-menu */}
    </>
  );
}

export default Header;
