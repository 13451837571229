import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getSessions } from '../services/session';

const useFetchSessions = (domainId, startDate, endDate, pollInterval = 30000) => {
  const [domainSessions, setDomainSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [networkError, setNetworkError] = useState(null);

  const history = useHistory();

  const fetchData = async () => {
    try {
      if (domainId) {
        getSessions({ domainId, startDate, endDate }, (sessions) => {
          setIsLoading(false);
          setDomainSessions(sessions);
        }, (error) => {
          const { code, message, response } = error;
          if (response?.status === 403) {
            history.push('/login');
          } else {
            const errorMessage = response?.data || `${code} - ${message}`;
            setNetworkError(errorMessage);
          }
        });
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') console.log(e);
    }
  };

  useEffect(() => {
    fetchData(); // Initial fetch

    const intervalId = setInterval(() => {
      fetchData(); // Fetch data at regular intervals
    }, pollInterval);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [domainId, startDate, endDate, history, pollInterval]);

  return [domainSessions, networkError, isLoading];
};

export default useFetchSessions;
