const exampleAreachartFills = [
  {
    field: 'First Name',
    value: 100,
  },
  {
    field: 'Last Name',
    value: 99,
  },
  {
    field: 'Email',
    value: 90,
  },
  {
    field: 'Phone',
    value: 90,
  },
  {
    field: 'Address',
    value: 85,
  },
  {
    field: 'City',
    value: 75,
  },
  {
    field: 'Previous Job Title',
    value: 78,
  },
  {
    field: 'Previous Job Description',
    value: 70,
  },
];

const examplePiechartCompletion = [
  { name: 'Completed', value: 400 },
  { name: 'Abandoned', value: 150 },
];

const exampleBarchartNumberOfInteractions = [
  {
    date: '31st Oct 2022',
    value: 40,
  },
  {
    date: '1st Nov 2022',
    value: 42,
  },
  {
    date: '2nd Nov 2022',
    value: 30,
  },
  {
    date: '3rd Nov 2022',
    value: 35,
  },
  {
    date: '4th Nov 2022',
    value: 50,
  },
  {
    date: '6th Nov 2022',
    value: 52,
  },
  {
    date: '7th Nov 2022',
    value: 13,
  },
];

const exampleBarchartTotalTimeSpent = [
  {
    date: '31st Oct 2022',
    value: 10,
  },
  {
    date: '1st Nov 2022',
    value: 22,
  },
  {
    date: '2nd Nov 2022',
    value: 20,
  },
  {
    date: '3rd Nov 2022',
    value: 15,
  },
  {
    date: '4th Nov 2022',
    value: 30,
  },
  {
    date: '6th Nov 2022',
    value: 32,
  },
  {
    date: '7th Nov 2022',
    value: 13,
  },
];

const exampleBarchartAverageTimeSpent = [
  {
    date: '31st Oct 2022',
    value: 2,
  },
  {
    date: '1st Nov 2022',
    value: 3,
  },
  {
    date: '2nd Nov 2022',
    value: 3,
  },
  {
    date: '3rd Nov 2022',
    value: 2,
  },
  {
    date: '4th Nov 2022',
    value: 4,
  },
  {
    date: '6th Nov 2022',
    value: 4,
  },
  {
    date: '7th Nov 2022',
    value: 2,
  },
];

const examplePiechartDevice = [
  { name: 'Web', value: 400 },
  { name: 'Mobile', value: 150 },
];

const exampleHistogramDeviceCompletion = [
  {
    name: 'Web',
    completed: 300,
    abandoned: 100,
  },
  {
    name: 'Mobile',
    completed: 100,
    abandoned: 24,
  },
];

const exampleBarchartLoaction = [
  { name: 'America/Denver', value: 400 },
  { name: 'Europe/London', value: 300 },
  { name: 'Australia/Sydney', value: 300 },
  { name: 'America/Los Angeles', value: 200 },
];

const exampleHistogramVisitorLocation = [
  {
    name: 'America/Denver',
    completed: 300,
    abandoned: 100,
  },
  {
    name: 'Europe/London',
    completed: 280,
    abandoned: 20,
  },
  {
    name: 'Australia/Sydney',
    completed: 200,
    abandoned: 100,
  },
  {
    name: 'America/Los Angeles',
    completed: 180,
    abandoned: 20,
  },
];

export {
  exampleAreachartFills,
  examplePiechartCompletion,
  exampleBarchartNumberOfInteractions,
  exampleBarchartAverageTimeSpent,
  exampleBarchartTotalTimeSpent,
  examplePiechartDevice,
  exampleBarchartLoaction,
  exampleHistogramDeviceCompletion,
  exampleHistogramVisitorLocation,
};
