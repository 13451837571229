import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as R from 'ramda';
import moment from 'moment';
import theme from '../../theme';
import {
  updateUserDetails, updateUserPwd, deleteUser, updateAuthenticatedUserDetails,
  updateAuthenticatedUserPackageStatus,
} from '../../services/user';
import { cancelPackage } from '../../services/payment';
import { logout } from '../../services/auth';

const contentStyle = {
  height: 'calc(90vh - 100px)',
  minHeight: 500,
  width: '100%',
};

const formInputStyle = {
  width: '100%',
  borderRadius: '8px',
  fontSize: '16px',
  border: 'solid 1px #d6d6d6',
  padding: '0 52px 0 20px',
  color: theme.colors.textColor,
  background: 'transparent',
  fontFamily: 'Rubik',
  textOverflow: 'ellipsis',
};

const breakStyle = {
  width: '100%',
};

const saveBtnStyle = {
  padding: '8px 24px',
  background: theme.colors.main,
  borderRadius: 8,
  color: '#fff',
};

const actionBtnStyle = {
  padding: '8px 24px',
  background: theme.colors.red,
  borderRadius: 8,
  color: '#fff',
  width: '100%',
};

const notifySuccess = () => toast.success('Done', {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

const notifyError = (message) => toast.error(message || 'Error', {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

const getPackageButtonLabel = (billingEnd, packageClickCount) => {
  if (billingEnd) return `Package active until ${moment.unix(billingEnd).format('Do MMM YY')}`;
  return packageClickCount < 1 ? 'Cancel package' : 'Yes I\'m sure!';
};

function AccountEditor({
  closeModal,
  userName,
  userEmail,
  userId,
  packageName,
  billingEnd,
}) {
  const [userDetails, setUserDetails] = useState({ name: userName, email: userEmail });
  const [userPwd, setUserPwd] = useState({ currentPassword: '', newPassword: '' });
  const [clickCount, setClickCount] = useState(0);
  const [packageClickCount, setPackageClickCount] = useState(0);

  const disableUpdateDetails = R.equals(userName, userDetails.name)
  && R.equals(userEmail, userDetails.email);

  const disableUpdatePwd = !userPwd.currentPassword?.length
  || !userPwd.newPassword?.length;

  const disableCancelPackage = packageName === 'free' || billingEnd;

  const onDetailsChange = (event) => {
    const { name, value } = event.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };

  const onPwdChange = (event) => {
    const { name, value } = event.target;
    setUserPwd((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveDetails = () => {
    // Check name length?
    updateUserDetails(
      { userId, ...userDetails },
      (user) => {
        updateAuthenticatedUserDetails(user);
        notifySuccess();
        closeModal();
      },
      (error) => {
        const { code, message, response } = error;
        const errorMessage = response.data || `${code} - ${message}`;
        notifyError(errorMessage);
      },
    );
  };

  const handleSavePwd = () => {
    updateUserPwd(
      { userId, ...userPwd },
      () => {
        notifySuccess();
        setTimeout(logout, 1000);
      },
      (error) => {
        const { code, message, response } = error;
        const errorMessage = response.data || `${code} - ${message}`;
        notifyError(errorMessage);
      },
    );
  };

  const handleDeteUser = () => {
    setClickCount(clickCount + 1);
    if (clickCount >= 1) {
      deleteUser(
        { userId },
        () => {
          notifySuccess();
          setTimeout(logout, 1000);
        },
        (error) => {
          const { code, message, response } = error;
          const errorMessage = response.data || `${code} - ${message}`;
          notifyError(errorMessage);
        },
      );
    }
  };

  const handleCancelPackage = () => {
    setPackageClickCount(packageClickCount + 1);
    if (packageClickCount >= 1) {
      cancelPackage(
        { userId },
        (user) => {
          updateAuthenticatedUserPackageStatus(user);
          notifySuccess();
          closeModal();
        },
        (error) => {
          const { code, message, response } = error;
          const errorMessage = response.data || `${code} - ${message}`;
          notifyError(errorMessage);
        },
      );
    }
  };

  return (
    <div
      className="form-style-light mb-0"
      style={{ borderRadius: 20, padding: 0 }}
    >
      <div className="mixitUp-container d-flex flex-wrap">
        <div
          className="portfolio-block-two portfolio-plain d-flex flex-row justify-content-center position-relative"
          style={contentStyle}
        >
          <button
            type="button"
            className="fancybox fancybox-edit fancybox-edit-right"
            onClick={() => closeModal(null)}
          >
            <i className="fa fa-times" aria-hidden="true" />
          </button>
          <div
            className="d-flex align-items-end flex-column position-relative h-100"
            style={{ gap: 16, overflowY: 'auto', padding: '80px 8px 30px' }}
          >
            <input
              type="text"
              value={userDetails.name}
              onChange={onDetailsChange}
              style={formInputStyle}
              placeholder="Name"
              name="name"
            />
            <input
              type="text"
              value={userDetails.email}
              onChange={onDetailsChange}
              style={formInputStyle}
              placeholder="Email"
              name="email"
            />

            <button
              type="button"
              onClick={handleSaveDetails}
              style={{
                ...saveBtnStyle,
                ...(disableUpdateDetails ? { background: theme.colors.textColor } : {}),
              }}
              disabled={disableUpdateDetails}
            >
              Update
            </button>
            <input
              placeholder="Current password"
              style={formInputStyle}
              type="password"
              name="currentPassword"
              value={userPwd.currentPassword}
              onChange={onPwdChange}
            />
            <input
              placeholder="New password"
              style={formInputStyle}
              type="password"
              name="newPassword"
              value={userPwd.newPassword}
              onChange={onPwdChange}
            />
            <button
              type="button"
              onClick={handleSavePwd}
              style={{
                ...saveBtnStyle,
                ...(disableUpdatePwd ? { background: theme.colors.textColor } : {}),
              }}
              disabled={disableUpdatePwd}
            >
              Update
            </button>
            <hr style={breakStyle} />
            <button
              type="button"
              onClick={handleCancelPackage}
              style={{
                ...actionBtnStyle,
                ...(disableCancelPackage ? { background: theme.colors.textColor } : {}),
              }}
              disabled={disableCancelPackage}
            >
              {getPackageButtonLabel(billingEnd, packageClickCount)}
            </button>
            <hr style={breakStyle} />
            <button
              type="button"
              style={actionBtnStyle}
              onClick={handleDeteUser}
            >
              {clickCount < 1 ? 'Delete account' : 'Yes I\'m sure!'}
            </button>
          </div>
        </div>
      </div>
    </div>

  );
}

export default AccountEditor;
